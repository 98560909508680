import NaWieki from "@/shared/application/nawieki";
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { ITranslationModel } from "@/shared/models/translation-model";
import LanguageStore from "@/stores/language-store";
import Component from "vue-class-component";
@Component
export default class TranslationsComponent extends NaWiekiComponentBase {

    addTranslationDialog = false;

    availableLangs = LanguageStore.getAvailableLanguages();

    addedLangs: Array<ILanguageDto> = [{ languageName: "polski", languageCode: "pl" }] ;
    supportedLangs = ["ua", "pl", "us"];
    selectedLang: string = "";

    async mounted() {
        await this.$store.dispatch('loadWebsiteIfNeeded');
        NaWieki.isLoading = true;
        this.$store.dispatch('loadTranslations').then((res) => {
            NaWieki.isLoading = false;
            var translations = this.$store.state.translations;
            translations.forEach((element: ITranslationModel) => {
                var foundLang = this.availableLangs.find(x => x.languageCode == element.languageCode);
                this.addedLangs.push({ languageName: foundLang?.languageName!, languageCode: foundLang?.languageCode! });
                this.availableLangs = this.availableLangs.filter(x => x.languageCode != foundLang?.languageCode!);
            });
        });
    }

    showAddTranslation() {
        if(this.supportedLangs.indexOf(this.selectedLang) < 0) {
            this.swalAlert('error', "Niestety wybrany język nie jest jeszcze przez nas wspierany. Jeśli potrzebujesz dodać ten język na swojej stronie skontaktuj się z nami pod adresem kontakt@nawieki.pl");
            return;
        }
        this.$router.push('translation/' + this.selectedLang);
    }

    editTranslation(translation: ITranslationModel) {
        var editedTranslation = this.$store.state.translations.find((x: ITranslationModel) => x.languageCode == translation.languageCode);
        this.$router.push('translation/' + editedTranslation.languageCode + "/" + editedTranslation.id);
    }

    async deleteTranslation(translation: ITranslationModel) {
        var editedTranslation = this.$store.state.translations.find((x: ITranslationModel) => x.languageCode == translation.languageCode);
        await this.$store.dispatch('deleteTranslation', editedTranslation.id);
    }
}
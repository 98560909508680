

















import { Component, Watch } from 'vue-property-decorator';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { availableDomains } from '@/stores/app-store';
import AddressHelper from '@/shared/helpers/address-helper';
import TemplateChooser from '@/main/components/home/template-chooser.vue';

@Component({
  components: {
    TemplateChooser: require('@/main/components/home/template-chooser.vue').default
  },
  computed: {
    canGenerate: {
      get() {
        return !(this as any).showDomainReserved && (this as any).websiteAddress != "" && (this as any).selectedTemplateId != 0;
      }
    },
    websiteAddress: {
      get() {
        return (this as any).$store.getters.fullWebsiteAddress();
      }
    },
    websiteAddressWithScheme: {
      get() {
        return "http://" + (this as any).$store.getters.fullWebsiteAddress();
      }
    }
  }
})
export default class OnboardCreatingComponent extends NaWiekiComponentBase {
  public loading: boolean = true;

  mounted() {
    if(!this.$store.state.website.weddingDate) {
      this.$router.push({ path: '/onboarding/start' });
      return;
    }
    this.NaWieki.loadingBlocked = true;
    this.$store.dispatch("saveWebsite").then(x => {
      this.loading = false;
      this.NaWieki.loadingBlocked = false;
    })
  }

  goToMain() {
    this.$router.push({ path: '/main/home' });
  }
}

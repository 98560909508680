import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Prop } from 'vue-property-decorator';

@Component({
    computed: {
        giftModel: {
            get() {
                return (this as any).gift;
            }
        },
        isOpenValue: {
            get() {
                return (this as any).isOpen;
            },
            set() {
                this.$emit("closeDialog");
            }
        }
    }
})
export default class GiftDialogComponent extends NaWiekiComponentBase {
    @Prop() public gift!: IGiftModel;
    @Prop() public isOpen!: boolean;


    statusesDict = [{ id: 0, name: "Dostępny" }, { id: 1, name: "Zarezerwowany" }];


    async saveGift() {
        await this.$store.dispatch("saveGift", (this as any).giftModel);
        this.$emit("closeDialog");
    }

    close() {
        this.$emit("closeDialog");
    }
}
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';



@Component
export default class TemplateChooserComponent extends NaWiekiComponentBase {
  @Prop() public templateId!: number;

  @Watch('templateId')
  onChildChanged(val: number, oldVal: number) {
    this.selectedTemplateId = val;
  }

  public mounted() {
    this.selectedTemplateId = !!this.templateId ? this.templateId! : -1;
  }

  private templates = [
    {
      id: 2,
      name: "Klasyczny",
      imgs: [
        '/template2img1.png',
        '/template2img2.png',
        '/template2img3.png',  
      ]
    },
    {
      id: 3,
      name: "Ruskus",
      imgs: [
        '/template3img1.png',
 
      ]
    },
    {
      id: 4,
      name: "Burgund",
      imgs: [
        '/template4img1.png',
        '/template4img2.png',
        '/template4img3.png',
 
      ]
    },
    {
      id: 5,
      name: "Fiołek",
      imgs: [
        '/template5img1.png',
        '/template5img2.png',
        '/template5img3.png',
 
      ]
    },
    // {
    //   id: 6,
    //   name: "TBD",
    //   imgs: [
    //     '/template5img1.png',
    //     '/template5img2.png',
    //     '/template5img3.png',
 
    //   ]
    // }
  ];
  public selectedTemplateId: number | undefined = -1;

  @Emit()
  selectTemplate(id: number): void {
    this.selectedTemplateId = id;
  }
}
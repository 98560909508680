var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"auto","fullscreen":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Wybierz lokalizację obiektu... ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-xs-12 col-lg-6 col-md-6 col-sm-12",attrs:{"cols":"12"}},[_c('gmap-autocomplete',{staticClass:"introInput",on:{"place_changed":_vm.setPlace},scopedSlots:_vm._u([{key:"input",fn:function(slotProps){return [_c('v-text-field',{ref:"input",attrs:{"outlined":"","prepend-inner-icon":"mdi-map-marker","placeholder":"Wpisz nazwę bądź adres miejsca...","dense":""},on:{"listeners":slotProps.listeners,"attrs":slotProps.attrs}})]}}])})],1),_c('v-col',{staticClass:"col-xs-12 col-sm-12 col-md-2"},[_c('v-btn',{on:{"click":_vm.addMarker}},[_vm._v("Wyszukaj")])],1)],1)],1),(_vm.currentPlaceName != "")?_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',{staticClass:"mb-4"},[_vm._v(" Wybrałeś: "+_vm._s(_vm.currentPlaceName)+", "+_vm._s(_vm.currentPlaceAddress)+" ")])])],1)],1):_vm._e(),_c('GmapMap',{staticClass:"gmap",attrs:{"center":_vm.center,"zoom":6,"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUI: true,
            clickableIcons: true,
          }},on:{"click":_vm.mapClicked}},[_c('GmapMarker',{attrs:{"position":_vm.marker.position,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = _vm.marker.position}}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.savePlace}},[_vm._v("Anuluj")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.savePlace}},[_vm._v("Zapisz")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
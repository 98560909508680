<template>
  <v-container>
    <v-row>
      <div class="centerize">
        <h1 class="text-center">Dziękujemy za dokonanie płatności!</h1>
        <br /><br />
        <div class="d-flex">
          <v-icon class="mark-sign">mdi-check-outline</v-icon>
        </div>
        <br /><br />
        <h3 class="text-justify">
          Przetwarzamy ją w systemie, daj nam kilka minut, by odpowiedni pakiet
          został aktywowany. Potwierdzenie aktywacji pakietu otrzymasz również
          drogą e-mailową. Wyloguj się i zaloguj się ponownie za kilka minut.
        </h3>
        <br />
        <p class="text-justify">
          W przypadku niezaaplikowania się pakietu bądź innych problemów prosimy
          o wiadomość na adres
          <a href="mailto:kontakt@nawieki.pl">kontakt@nawieki.pl</a>
        </p>
        <v-btn
          class="button-center"
          color="primary"
          x-large
          @click="
            authStore.removeToken();
            $router.push({ path: '/account/login' });
          "
          >Zaloguj się ponownie</v-btn
        >
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
.button-center {
  margin: auto;
  width: 100%;
  margin-top: 40px;
}

.centerize {
  width: 550px;
  margin: auto;
  margin-top: 150px;
}

@media only screen and (max-width: 768px) {
  .centerize {
    width: unset !important;
    margin: unset !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 70px !important;
  }

  .button-center {
    margin-top: 20px;
  }
}

.mark-sign {
  margin: auto;
  font-size: 100px !important;
  color: #4caf50 !important;
}
</style>
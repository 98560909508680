export default class SmsHelper {
    public static getSmsCount(content: string): number {
        if (!content) {
            return 0;
        }
        var [_, smsCount] = this.getContentData(content);

        return smsCount;
    }

    public static getRemainingChars(content: string): number {
        if (!content) {
            return 0;
        }
        var [remainingChars, _] = this.getContentData(content);

        return remainingChars;
    }

    public static isContentLimitReached(content: string): boolean {
        if (!content) {
            return false;
        }
        var [remainingChars, smsCount] = this.getContentData(content);
        if(smsCount >= 6 && remainingChars == 0) {
            return true;
        }
        return false;
    }

    public static isBeyondLimit(content: string): boolean {
        if (!content) {
            return false;
        }
        var [remainingChars, smsCount] = this.getContentData(content);
        
        return content.length > 0 && remainingChars == 0 && smsCount == 0;
    }

    private static getContentData(content: string): [number, number] {
        var contentLength = content.length;


        var contentArray = Array.from(content);
        var containsPolishDiacritics = this.containsPolishDiacritics(content);
        var isNotAscii = !this.isASCII(content);

        contentArray.forEach(char => {
            if (!containsPolishDiacritics && this.doubleSizeChars.some(x => x.includes(char))) {
                contentLength += 1;
            }
        });

        var limitsArray = containsPolishDiacritics || isNotAscii ? this.limitsWithDiacritics : this.limitsStandard;

        for (var i = 0; i < limitsArray.length; i++) {

            if (contentLength <= limitsArray[i].limit) {
                return [limitsArray[i].limit - contentLength, limitsArray[i].sms];
            }
        }


        return [0, 0];
    }

    public static containsPolishDiacritics(content: string): boolean {
        if (!content) {
            return false;
        }
        var contentArray = Array.from(content);
        for (var i = 0; i < contentArray.length; i++) {
            if (this.polishDiacritics.some(x => x.includes(contentArray[i]))) {
                return true;
            }
        }
        return false;
    }

    public static isASCII(str: string) {
        return /^[\x00-\x7F]*$/.test(str);
    }

    private static polishDiacritics = ["ą", "ć", "ę", "ł", "ń", "ó", "ś", "ź", "ż"];
    private static doubleSizeChars = ["^", "{", "}", "[", "]", "~", "\\", "|", "€"];

    private static limitsStandard = [
        { "sms": 1, "limit": 160 },
        { "sms": 2, "limit": 306 },
        { "sms": 3, "limit": 459 },
        { "sms": 4, "limit": 612 },
        { "sms": 5, "limit": 765 },
        { "sms": 6, "limit": 918 }
    ];

    private static limitsWithDiacritics = [
        { "sms": 1, "limit": 70 },
        { "sms": 2, "limit": 134 },
        { "sms": 3, "limit": 201 },
        { "sms": 4, "limit": 268 },
        { "sms": 5, "limit": 335 },
        { "sms": 6, "limit": 402 }
    ];
}
import NaWieki from '@/shared/application/nawieki';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { IGuestModel } from '@/shared/models/guest-model';
import { Component, Watch } from 'vue-property-decorator';

@Component({
    components: {
        GiftDialog: require('@/main/views/gifts/gift-dialog.vue').default,
    },

    computed: {
        gifts: {
            get() {
                return this.$store.state.gifts;
            }
        },
        giftsDescription: {
            get() {
                return this.$store.state.website.giftsDescription;
            },
            set(value) {
                this.$store.commit('setGiftsDescription', value);
            }
        },
        giftsDescriptionEnabled: {
            get() {
                return this.$store.state.website.giftsDescriptionEnabled;
            }
        },
        giftsListEnabled: {
            get() {
                return this.$store.state.website.giftsListEnabled;
            }
        },
        giftsSelector: {
            get() {
                if(this.$store.state.website.giftsDescriptionEnabled && this.$store.state.website.giftsListEnabled ) {
                    return 2;
                } 
                else if (this.$store.state.website.giftsDescriptionEnabled) {
                    return 1;
                }
                return 0;
            },
            set(value) {
                if(value == 0) {
                    this.$store.commit('setGiftsDescriptionEnabled', false);
                    this.$store.commit('setGiftsListEnabled', false);
                } else if(value == 1) {
                    this.$store.commit('setGiftsDescriptionEnabled', true);
                    this.$store.commit('setGiftsListEnabled', false);
                } else {
                    this.$store.commit('setGiftsDescriptionEnabled', true);
                    this.$store.commit('setGiftsListEnabled', true);
                }
                this.$store.dispatch('saveWebsite');
            }
        }
    }
})
export default class GiftsComponent extends NaWiekiComponentBase {
    private loading = false;
    giftDialog: boolean = false;
    dialogStatus: boolean = false;
    dialogDelete: boolean = false;
    editedStatus = 0;
    editedGift: IGiftModel = {
        id: null,
        name: "",
        link: "",
        price: null,
        reservedGuest: null,
        status: 0,
        unknownReservedEmail: ""
    };
    defaultGift: IGiftModel = {
        id: null,
        name: "",
        link: "",
        price: null,
        reservedGuest: null,
        status: 0,
        unknownReservedEmail: ""
    };
    headers = [
        {
            text: 'Nazwa prezentu',
            align: 'start',
            value: 'name',
        },
        { text: 'Odnośnik', value: 'link' },
        { text: 'Status', value: 'status' },
        { text: 'Zarezerwowany przez...', value: 'reserved' },
        { text: 'Szacunkowa cena', value: 'price' },
        { text: 'Akcje', value: 'actions', sortable: false },
    ];

    private giftsOptions = [{ text: "Brak informacji o prezentach", value: 0 }, { text: "Opis słowny pożądanych prezentów", value: 1 }, { text: "Lista prezentów możliwych do zarezerwowania", value: 2 }];

    async mounted() {
        NaWieki.isLoading = true;
        await this.$store.dispatch('loadWebsiteIfNeeded');
        await this.$store.dispatch('loadGuests');
        this.$store.dispatch('loadGifts').then((res) => {
            NaWieki.isLoading = false;
        });

    }

    getStatusColor(status: number) {
        switch (status) {
            case 0: return "green";
            case 1: return "light-blue";
        }
    }

    getStatusLabel(status: number) {
        switch (status) {
            case 0: return "Dostępny";
            case 1: return "Zarezerwowany";
        }
    }

    getReservedLabel(gift: IGiftModel) {
        if (gift.reservedGuest) {
            var guests = (this.$store.state.guests as Array<IGuestModel>).filter((x: IGuestModel) => x.id == gift.reservedGuest);
            if (guests.length === 1) {
                return `${guests[0].firstName} ${guests[0].lastName} (${guests[0].email})`;
            }
        }
        else if (gift.unknownReservedEmail) {
            return gift.unknownReservedEmail;
        }
        return "---";
    }

    async saveDescription() {
        await this.$store.dispatch('saveWebsite');
    }

    openNewGiftDialog() {
        this.editedGift = Object.assign({}, this.defaultGift);
        this.giftDialog = true;
    }

    async deleteGift() {
        this.dialogDelete = false;
        this.loading = true;
        await this.$store.dispatch('deleteGift', this.editedGift.id);
        this.loading = false;
    }
}
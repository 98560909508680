




















import { Component } from "vue-property-decorator";
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";

@Component
export default class ConfirmationErrorComponent extends NaWiekiComponentBase {
  onSubmit() {
    this.$router.push({ path: "/account/login" });
  }
}

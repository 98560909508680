import { Component } from 'vue-property-decorator';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';

@Component
export default class RegisterComponent extends NaWiekiComponentBase {
    refs = this.$refs as any;
    registerInput = {} as IRegisterInput;
    errors: INameValueDto[] = [];
    resultMessage: string | undefined;
    registerComplete = false;
    rulesSelected = false;
    showError = false;

    onSubmit() {
        if(!this.rulesSelected) {
            this.showError = true;
            return;
        }
        if (!this.registerInput.brideEmail && !this.registerInput.groomEmail) {
            this.errors.push({ name: "ss", value: "lol" } as INameValueDto);
            return;
        }
        if (this.refs.form.validate()) {
            this.NaWiekiService.post<IRegisterOutput>('/api/register', this.registerInput)
                .then((response) => {
                    if (!response.isError) {
                        this.resultMessage = this.$t('AccountCreationSuccessful').toString();
                        this.registerComplete = true;
                    } else {
                        this.errors = response.errors;
                    }
                });
        }
    }
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"col-auto mr-auto"},[_c('h2',[_vm._v("Dodaj nową komunikację")])])],1),_c('v-row',[_c('v-col',{staticClass:"col-lg-4 col-sm-12"},[_c('div',{staticClass:"text-subtitle-2"},[_vm._v(" Wysyłaj wiadomość do swoich gości w zaplanowanym czasie. Przy wyborze wysyłki do wszystkich priorytetyzowana jest wysyłka SMS, jeśli gość ma wypełnione pole z numerem telefonu. SMSy wysyłane są z dostępnej dla Ciebie puli. Wysyłka e-maili jest darmowa. Nigdy nie kontaktujemy się z Twoimi gośćmi poza zdefiniowanymi przez Ciebie komunikacjami. ")])])],1),_c('v-row',[_c('v-col',{staticClass:"col-lg-4 col-sm-12 col-md-6"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.model.plannedDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "plannedDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "plannedDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data wysłania","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.model.plannedDate),callback:function ($$v) {_vm.$set(_vm.model, "plannedDate", $$v)},expression:"model.plannedDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","locale":"pl","no-title":"","scrollable":"","min":_vm.getToday},model:{value:(_vm.model.plannedDate),callback:function ($$v) {_vm.$set(_vm.model, "plannedDate", $$v)},expression:"model.plannedDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.savePlannedDate(_vm.model.plannedDate)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"timeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.model.plannedTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.model, "plannedTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.model, "plannedTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Godzina wysłania","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(_vm.model.plannedTime),callback:function ($$v) {_vm.$set(_vm.model, "plannedTime", $$v)},expression:"model.plannedTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[(_vm.timeMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.savePlannedTime(_vm.model.plannedTime)}},model:{value:(_vm.model.plannedTime),callback:function ($$v) {_vm.$set(_vm.model, "plannedTime", $$v)},expression:"model.plannedTime"}}):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nazwa komunikacji","required":"","outlined":"","hint":"Wartość tego pola nie będzie dołączona do komunikacji, służy jedynie w celach identyfikacyjnych na liście komunikacji."},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"label":"Treść","outlined":"","hint":_vm.getContentHint,"persistent-hint":"","required":""},on:{"input":_vm.contentChanged},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.model.content),callback:function ($$v) {_vm.$set(_vm.model, "content", $$v)},expression:"model.content"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.types,"label":"Wybierz formę komunikacji","outlined":"","hint":_vm.getHint,"persistent-hint":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('span',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.selectedType),callback:function ($$v) {_vm.selectedType=$$v},expression:"selectedType"}})],1)],1),(_vm.selectedType == 0 || _vm.selectedType == 2)?_c('v-row',[_c('v-col',[_c('span',[_vm._v("Przy obecnej liście gości wykorzystasz "+_vm._s(_vm.currentCommunicationSmsUsage)+" SMS.")])])],1):_vm._e()],1)],1),(_vm.selectedType == 1)?_c('v-col',{staticClass:"col-sm-12 col-md-6 col-lg-7 offset-lg-1"},[_c('v-row',{staticClass:"pb-3"},[_c('h4',[_vm._v("Wybierz gości")])]),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.guests,"sort-by":"lastName"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Brak dodanych gości")])]},proxy:true},{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [(item.phoneNumber)?_c('span',[_vm._v(_vm._s(item.phoneNumber))]):_c('span',[_c('i',[_vm._v("---")])])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('span',[_vm._v(_vm._s(item.email))]):_c('span',[_c('i',[_vm._v("---")])])]}},{key:"item.smsSelected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.phoneNumber},on:{"click":function($event){return _vm.smsSelectionChanged(item)}},model:{value:(item.smsSelected),callback:function ($$v) {_vm.$set(item, "smsSelected", $$v)},expression:"item.smsSelected"}})]}},{key:"item.emailSelected",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":!item.email},model:{value:(item.emailSelected),callback:function ($$v) {_vm.$set(item, "emailSelected", $$v)},expression:"item.emailSelected"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"pt-4"},[_c('p',[_vm._v(" Posiadasz aktualnie do wykorzystania "+_vm._s(_vm.smsRemaining - _vm.plannedSmsUsage)+" SMS ("+_vm._s(_vm.smsRemaining)+" SMS na koncie - "+_vm._s(_vm.plannedSmsUsage)+" SMS w zaplanowanych komunikacjach). Obecna komunikacja wyniesie "+_vm._s(_vm.currentCommunicationSmsUsage)+" SMS. ")]),(
            _vm.smsRemaining - _vm.plannedSmsUsage - _vm.currentCommunicationSmsUsage >= 0
          )?_c('p',[_vm._v(" Pozostanie Ci: "),_c('b',[_vm._v(_vm._s(_vm.smsRemaining - _vm.plannedSmsUsage - _vm.currentCommunicationSmsUsage))]),_vm._v(" SMS ")]):_c('p',{staticClass:"red--text"},[_vm._v(" Nie masz wystarczającej ilości SMS by zrealizować komunikację ")])])],1):_vm._e()],1),(_vm.validationError)?_c('v-row',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Wypełnij wszystkie pola!")])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"large":"","color":"primary","block":"","disabled":_vm.remainingChars <= 0 && _vm.smsCount >= 6},on:{"click":_vm.saveCommunication}},[_vm._v("Zapisz")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
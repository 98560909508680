import NaWiekiService from "../application/nawieki-service-proxy";

export default class AddressHelper {

    public static buildWebsiteAddress(store: any): string {
        if(store.state.website.brideName == undefined) {
            return "";
        }
        return this.replacePolishChars(store.state.website.brideName.toLowerCase()) + `i` + this.replacePolishChars(store.state.website.groomName.toLowerCase());
    }

    public static async checkAddressReserved(address: string, domain: string, naWiekiService: NaWiekiService) {
        return await naWiekiService.get<boolean>(
            '/api/website/isWebsiteAddressAllowed/' +
            address + '/' +
            domain, false);
    }

    private static replacePolishChars(napis: string): string {
        napis = napis.replace("ę", "e");
        napis = napis.replace("ó", "o");
        napis = napis.replace("ą", "a");
        napis = napis.replace("ś", "s");
        napis = napis.replace("ł", "l");
        napis = napis.replace("ż", "z");
        napis = napis.replace("ź", "z");
        napis = napis.replace("ć", "c");
        napis = napis.replace("ń", "n");
        return napis;
    }
}
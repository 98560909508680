






















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';

@Component({
  computed: {
    currentPlaceName: {
      get() {
        if ((this as any).currentPlace == null) {
          return "";
        }
        return (this as any).currentPlace.name;
      }
    },
    currentPlaceAddress: {
      get() {
        if ((this as any).currentPlace == null) {
          return "";
        }
        return (this as any).currentPlace.formatted_address;
      }
    }
  }
})
export default class LocationPickerComponent extends NaWiekiComponentBase {

  @Prop() public showDialog!: boolean;

  @Watch("showDialog")
  onShowDialog(val: boolean) {
    this.dialog = this.showDialog;
  }

  public dialog = false;
  public currentPlace: any | null = null;
  public center: any = { lat: 51.9189046 , lng: 19.1343786 };
  public marker: any = { position: null };
  public currentPlaceId = "";

  setPlace(place: any) {
    this.currentPlace = place;
    this.addMarker();
  }

  async mapClicked(clickedPlace: any, lol: any) {
    const marker = {
      lat: clickedPlace.latLng.lat(),
      lng: clickedPlace.latLng.lng(),
    };
    this.marker = { position: marker };
    if (!!clickedPlace.placeId) {
      this.currentPlaceId = clickedPlace.placeId;
      let response = await this.$store.dispatch("getPlaceById", clickedPlace.placeId);
      this.currentPlace = {
        name: response.content.name,
        formatted_address: response.content.address
      };
    } else {
      let response = await this.$store.dispatch("getPlaceByLatLon", { lat: marker.lat, lon: marker.lng });
      this.currentPlace = {
        name: response.content.name,
        formatted_address: response.content.address
      };
    }
  }


  addMarker() {
    if (this.currentPlace) {
      const marker = {
        lat: this.currentPlace.geometry.location.lat(),
        lng: this.currentPlace.geometry.location.lng(),
      };
      this.marker = { position: marker };
      this.center = marker;
    }
  }

  savePlace() {
    this.$emit("placeSaved", { marker: this.marker, place: this.currentPlace, placeId: this.currentPlaceId });
  }
}

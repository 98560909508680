import Vue from "vue";
import Vuetify from "vuetify/lib";
import LanguageStore from '@/stores/language-store';

Vue.use(Vuetify);

import en from 'vuetify/src/locale/en';
import tr from 'vuetify/src/locale/tr';
import pl from 'vuetify/src/locale/pl';

export default new Vuetify({
    lang: {
        locales: { pl, en, tr },
        current: LanguageStore.getLanguage().languageCode,
    },
    icons: {
        iconfont: "mdi"
    },
    theme: {
        themes: {
          dark: {
            primary: "#0F3C2E",
            secondary: "#F4DB8E"
          },
          light: {
              primary: "#0F3C2E",
              secondary: "#F4DB8E"
          }
        },
      },
});
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { Component, Prop, Watch } from "vue-property-decorator";
// @ts-ignore
import { VueGooglePlaces } from 'vue-google-places';

@Component({
    components: {
        VueGooglePlaces: VueGooglePlaces,
        LocationPicker: require('@/onboarding/components/location-picker.vue').default,
    }
})
export default class EventFormComponent extends NaWiekiComponentBase {
    private menu = false;
    private timeMenu = false;
    private eventDate = "";
    private eventTime = "";
    private eventName = "";
    private eventAddress = "";
    private eventLatLon = "";
    private eventUserName = "";
    private showLocationPicker = false;

    @Prop() public eventType!: string;
    @Prop() public nameHint!: string;

    @Prop() public inputEventName!: string;
    @Prop() public inputEventDate!: string;
    @Prop() public inputEventTime!: string;
    @Prop() public inputEventAddress!: string;
    @Prop() public inputEventLatLon!: string;
    @Prop() public inputEventUserName!: string;

    mounted() {
        this.eventName = this.inputEventName;
        this.eventDate = this.inputEventDate;
        this.eventTime = this.inputEventTime;
        this.eventAddress = this.inputEventAddress;
        this.eventLatLon = this.inputEventLatLon; 
        this.eventUserName = this.inputEventUserName;
    }

    @Watch('eventName')
    @Watch('eventUserName')
    eventNameChanged(val: string, oldVal: string) {
        this.sendUpdate();
    }

    saveEventDate(date: string) {
        this.menu = false;
        (this.$refs.menu as any).save(date);
        this.sendUpdate();
    }


    saveEventTime(time: string) {
        this.timeMenu = false;
        (this.$refs.timeMenu as any).save(time);
        this.sendUpdate();
    }

    onEventAddressChanged(v: any) {
        //debugger;
        this.eventAddress = v.name + " (" + v.formatted_address + ")";
        this.eventUserName = v.name;
        this.eventLatLon = `${v.latitude},${v.longitude}`;
        this.sendUpdate();
    }


    addressSaved(address: any) {
        this.showLocationPicker = false;
        if (address.place == undefined) {
            return;
        }
        this.eventAddress = address.place.formatted_address
        this.eventUserName = address.place.name;
        this.eventLatLon = address.marker.position.lat + "," + address.marker.position.lng;
        this.sendUpdate();
    }

    sendUpdate() {
        this.$emit("update", this.eventName, this.eventDate, this.eventTime, this.eventAddress, this.eventLatLon, this.eventUserName);
    }
    close() {
        this.eventName = "";
        this.eventDate = "";
        this.eventTime = "";
        this.eventAddress = "";
        this.eventLatLon = "";
        this.eventUserName = "";
        this.sendUpdate();
        this.$emit("close");
    }



    get getToday() {
        return new Date().toJSON();
    }
}
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppConsts from '@/shared/application/nawieki';
// @ts-ignore
import { VueGooglePlaces } from 'vue-google-places';


@Component({
    components: {
        Panel: require('@/main/components/home/panels/panel.vue').default,
        EventForm: require('@/main/components/home/event-form.vue').default,
        LocationPicker: require('@/onboarding/components/location-picker.vue').default,
        VueGooglePlaces: VueGooglePlaces
    },
    computed: {
        churchAddress: {
            get() {
                return this.$store.state.website.churchAddress
            },
            set(value) {
                this.$store.commit('setChurchAddress', value)
            }
        },
        churchUserName: {
            get() {
                return this.$store.state.website.churchUserName
            },
            set(value) {
                this.$store.commit('setChurchUserName', value)
            }
        },
        weddingHallAddress: {
            get() {
                return this.$store.state.website.weddingHallAddress
            },
            set(value) {
                this.$store.commit('setWeddingHallAddress', value)
            }
        },
        weddingHallUserName: {
            get() {
                return this.$store.state.website.weddingHallUserName
            },
            set(value) {
                this.$store.commit('setWeddingHallUserName', value)
            }
        },
        ownDate: {
            get() {
                var val = this.$store.state.website.weddingDate;
                if (val == null) {
                    this.$store.commit('setWeddingDate', new Date().toISOString().split('T')[0]);
                    return new Date().toISOString().split('T')[0];
                }
                else {
                    return val;
                }
            },
            set(value) {
                this.$store.commit('setWeddingDate', value);
            }
        },
        ownTime: {
            get() {
                var val = this.$store.state.website.weddingTime;
                if (val == "00:00") {
                    this.$data.timeUnknown = true;
                    this.$data.timeDisabled = true;
                }
                return val;
            },
            set(value) {
                if (this.$data.timeMenu) {
                    this.$store.commit('setWeddingTime', value);
                }
            }
        },
        preWeddingEventName: {
            get() {
                return this.$store.state.website.preWeddingEventName;
            }
        },
        preWeddingEventDate: {
            get() {
                return this.$store.state.website.preWeddingEventDate;
            }
        },
        preWeddingEventTime: {
            get() {
                return this.$store.state.website.preWeddingEventTime;
            }
        },
        preWeddingEventAddress: {
            get() {
                return this.$store.state.website.preWeddingEventAddress;
            }
        },
        preWeddingEventLatLon: {
            get() {
                return this.$store.state.website.preWeddingEventLatLon;
            }
        },
        preWeddingEventUserName: {
            get() {
                return this.$store.state.website.preWeddingEventUserName;
            }
        },
        postWeddingEventName: {
            get() {
                return this.$store.state.website.postWeddingEventName;
            }
        },
        postWeddingEventDate: {
            get() {
                return this.$store.state.website.postWeddingEventDate;
            }
        },
        postWeddingEventTime: {
            get() {
                return this.$store.state.website.postWeddingEventTime;
            }
        },
        postWeddingEventAddress: {
            get() {
                return this.$store.state.website.postWeddingEventAddress;
            }
        },
        postWeddingEventLatLon: {
            get() {
                return this.$store.state.website.postWeddingEventLatLon;
            }
        },
        postWeddingEventUserName: {
            get() {
                return this.$store.state.website.postWeddingEventUserName;
            }
        },
    }
})
export default class CeremonyPanelComponent extends NaWiekiComponentBase {
    menu = false;
    timeMenu = false;
    timeUnknown = false;
    timeDisabled = false;

    showPreWeddingEvent = false;
    showPostWeddingEvent = false;

    showChurchPicker = false;
    showWeddingHallPicker = false;

    @Watch('preWeddingEventName')
    onPreWeddingEventNameChanged(val: string) {
        if (val) {
            this.showPreWeddingEvent = true;
        }
    }

    @Watch('postWeddingEventName')
    onPostWeddingEventNameChanged(val: string) {
        if (val) {
            this.showPostWeddingEvent = true;
        }
    }

    updated() {
        if ((this as any).preWeddingEventName) {
            this.showPreWeddingEvent = true;
        }
        if ((this as any).postWeddingEventName) {
            this.showPostWeddingEvent = true;
        }
    }

    saveWeddingDate(date: Date) {
        this.menu = false;
        (this.$refs.menu as any).save(date);
    }

    saveWeddingTime(time: string) {
        this.timeMenu = false;
        // var selectedTime = picker.inputHour + ":" + picker.inputMinute;
        // (this.$refs.timeMenu as any).save(selectedTime);
        // this.$store.commit('setWeddingTime', selectedTime);
    }

    timeKnowingnessChanged() {
        this.timeDisabled = this.timeUnknown;
        if (this.timeDisabled) {
            this.$store.commit('setWeddingTime', "00:00");
        }
    }

    // onChurchAddressChanged(v: any) {
    //     this.$store.commit('setChurchAddress',
    //         {
    //             address: v.name + " (" + v.formatted_address + ")",
    //             placeId: v.place_id,
    //             latLon: `${v.latitude},${v.longitude}`,
    //             userName: v.name
    //         }
    //     );
    // }

    // onWeddingHallAddressChanged(v: any) {
    //     this.$store.commit('setWeddingHallAddress',
    //         {
    //             address: v.name + " (" + v.formatted_address + ")",
    //             placeId: v.place_id,
    //             latLon: `${v.latitude},${v.longitude}`,
    //             userName: v.name
    //         }
    //     );
    // }

    preEventUpdated(eventName: string, eventDate: string, eventTime: string, eventAddress: string, eventLatLon: string, eventUserName: string) {
        this.$store.commit('setPreWeddingData', { eventName, eventDate, eventTime, eventAddress, eventLatLon, eventUserName });
    }

    postEventUpdated(eventName: string, eventDate: string, eventTime: string, eventAddress: string, eventLatLon: string, eventUserName: string) {
        this.$store.commit('setPostWeddingData', { eventName, eventDate, eventTime, eventAddress, eventLatLon, eventUserName });
    }

    churchAddressSaved(address: any) {
        this.showChurchPicker = false;

        if (address.place == undefined) {
            return;
        }
        this.$store.commit('setChurchAddress',
            {
                address: address.place.formatted_address,
                placeId: address.placeId,
                latLon: address.marker.position.lat + "," + address.marker.position.lng,
                userName: address.place.name
            }
        );
    }

    weddingHallAddressSaved(address: any) {
        this.showWeddingHallPicker = false;

        if (address.place == undefined) {
            return;
        }
        this.$store.commit('setWeddingHallAddress',
            {
                address: address.place.formatted_address,
                placeId: address.placeId,
                latLon: address.marker.position.lat + "," + address.marker.position.lng,
                userName: address.place.name
            }
        );
    }

    get getToday() {
        return new Date().toJSON();
    }
}
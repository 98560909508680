import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component } from 'vue-property-decorator';

@Component({
    computed: {
        isLoading: {
            get() {
                return (this as any).NaWieki.isLoading && !(this as any).NaWieki.loadingBlocked;
            }
        }
    }
})
export default class AppComponent extends NaWiekiComponentBase {
}
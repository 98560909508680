import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppConsts from '@/shared/application/nawieki';
import { ImageType } from '@/shared/models/image-type';

@Component
export default class ImageSelectorComponent extends NaWiekiComponentBase {

    @Prop() public singleFile!: boolean;
    @Prop() public existingFileIds!: string[];
    @Prop() public existingSingleFileId!: string;
    @Prop() public imageType!: ImageType;
    @Prop() public label!: string;

    @Watch('existingFileIds')
    onExistingFileIdsChanged(val: string[], oldVal: string[]) {
        this.fileIds = val;
    }

    @Watch('existingSingleFileId')
    onExistingSingleFileIdChanged(val: string, oldVal: string) {
        if (val) {
            this.fileIds = [];
            this.fileIds.push(val);
        }
    }

    private fileIds: string[];

    constructor() {
        super();
        this.fileIds = [];
    }

    uploadImages(files: File[]) {

        if (files == undefined || files.length == 0) {
            return;
        }
        var url = '/api/image/uploadImages/' + this.imageType;

        this.NaWiekiService.put<IFileModel[]>(url, files).then((response) => {
            var files: Array<string> = [];
            if (response.content instanceof Array) {
                files = response.content.map(x => x.fileId);
            } else {
                files.push((response.content as any).fileId);
            }
            if (this.singleFile) {
                this.$emit("filesUploaded", files[0]);
            } else {
                this.$emit("filesUploaded", files.concat(this.fileIds));
            }
        });
    }

    getImageUrl(fileId: string): string {
        return AppConsts.baseApiUrl + '/api/image/' + fileId + "/thumb";
    }

    removeImage(fileId: string): void {
        this.fileIds = this.fileIds.filter(x => x !== fileId);
        if (this.singleFile) {
            this.$emit("filesUploaded", "");
        } else {
            this.$emit("filesUploaded", this.fileIds);
        }
        this.NaWiekiService.delete('/api/image/removeImage/' + fileId).then((response) => {

        });
    }
}




















































import { Component, Watch } from 'vue-property-decorator';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';

@Component
export default class OnboardStartComponent extends NaWiekiComponentBase {


  public date: Date | null = null;
  public menu: boolean = false;
  public modal: boolean = false;
  public showNext: boolean = false;

  @Watch('modal')
  onDateChanged(newVal: boolean, oldVal: boolean) {
    if (!newVal && this.date != null) {
      this.$store.commit('setWeddingDate', this.date);
      this.showNext = true;
    }
  }

  mounted() {
    this.$store.dispatch('loadWebsite');
  }

  onNext() {
    this.$router.push({ path: '/onboarding/details' });
  }

  get getToday() {
    return new Date().toJSON();
  }
}

import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppConsts from '@/shared/application/nawieki';

@Component({
    components: {
        Panel: require('@/main/components/home/panels/panel.vue').default,
        ImageSelector: require('@/main/components/home/image-selector.vue').default
    },
    computed: {
        welcomeText: {
            get() {
                return this.$store.state.website.welcomeText
            },
            set(value) {
                this.$store.commit('setWelcomeText', value)
            }
        },
        mainPhotoFileId: {
            get() {
                return this.$store.state.website.mainPhotoFileId;
            },
            set(value) {
                //this.$store.commit('setWelcomeText', value)
            }
        },
        bridePhotoFileId: {
            get() {
                return this.$store.state.website.bridePhotoFileId;
            },
            set(value) {
                //this.$store.commit('setWelcomeText', value)
            }
        },
        groomPhotoFileId: {
            get() {
                return this.$store.state.website.groomPhotoFileId;
            },
            set(value) {
                //this.$store.commit('setWelcomeText', value)
            }
        },
        galleryPhotosFileIds: {
            get() {
                return this.$store.state.website.galleryPhotosFileIds;
            },
            set(value) {
                //this.$store.commit('setWelcomeText', value)
            }
        },
        templateId: {
            get() {
                return this.$store.state.website.templateId;
            }
        }
    }
})
export default class WebsiteDetailsPanelComponent extends NaWiekiComponentBase {

    mainPhotoFileUploaded(ids: string) {
        this.$store.commit('setMainPhotoFileId', ids);
    }

    galleryPhotosFileUploaded(ids: string[]) {
        this.$store.commit('setGalleryPhotosFileIds', ids);
    }
    bridePhotoFileUploaded(ids: string[]) {
        this.$store.commit('setBridePhotoFileId', ids);
    }
    groomPhotoFileUploaded(ids: string[]) {
        this.$store.commit('setGroomPhotoFileId', ids);
    }
}
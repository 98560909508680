































































































































import { Component, Watch } from "vue-property-decorator";
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";

@Component({
  components: {
    LocationPicker: require("@/onboarding/components/location-picker.vue")
      .default,
  },
  computed: {
    showNext: {
      get() {
        return (
          (this as any).hourUnknown ||
          ((this as any).weddingTimeHour != "" &&
            (this as any).weddingTimeMinute != "")
        );
      },
    },
  },
})
export default class OnboardEventComponent extends NaWiekiComponentBase {
  public weddingTimeHour: string = "";
  public weddingTimeMinute: string = "";
  public hourUnknown: boolean = false;

  public showChurchPicker = false;

  public churchName = "";
  public churchAddress = "";
  public churchLatLon = "";
  public churchPlaceId = "";

  public showWeddingHallPicker = false;

  public weddingHallName = "";
  public weddingHallAddress = "";
  public weddingHallLatLon = "";
  public weddingHallPlaceId = "";

  mounted() {
    if (!this.$store.state.website.weddingDate) {
      this.$router.push({ path: "/onboarding/start" });
      return;
    }
  }

  @Watch("weddingTimeHour")
  onHourChanged(val: string, oldVal: string) {
    if (val == "") {
      return;
    }
    if (!this.validateTime(val)) {
      this.$nextTick(() => {
        this.weddingTimeHour = oldVal;
      });
    }
    let intVal = parseInt(val);
    if (intVal > 23 || intVal < 0) {
      this.$nextTick(() => {
        this.weddingTimeHour = oldVal;
      });
    }
  }

  @Watch("weddingTimeMinute")
  onMinuteChanged(val: string, oldVal: string) {
    if (val == "") {
      return;
    }
    if (!this.validateTime(val)) {
      this.$nextTick(() => {
        this.weddingTimeMinute = oldVal;
      });
    }
    let intVal = parseInt(val);
    if (intVal > 59 || intVal < 0) {
      this.$nextTick(() => {
        this.weddingTimeMinute = oldVal;
      });
    }
  }

  @Watch("hourUnknown")
  onHourUnknownChanged(val: boolean, oldVal: boolean) {
    if (val) {
      this.weddingTimeHour = "";
      this.weddingTimeMinute = "";
    }
  }

  validateTime(val: string) {
    if (val.length > 2) {
      return false;
    }
    if (!Number.isInteger(parseInt(val))) {
      return false;
    }

    return true;
  }

  churchAddressSaved(address: any) {
    this.showChurchPicker = false;
    if (address.place == undefined) {
      return;
    }
    this.churchName = address.place.name;
    this.churchAddress = address.place.formatted_address;

    this.churchLatLon =
      address.marker.position.lat + "," + address.marker.position.lng;
    this.churchPlaceId = address.placeId;
    this.scrollToNextButton();
  }

  weddingHallAddressSaved(address: any) {
    this.showWeddingHallPicker = false;
    if (address.place == undefined) {
      return;
    }
    this.weddingHallName = address.place.name;
    this.weddingHallAddress = address.place.formatted_address;
    this.weddingHallLatLon =
      address.marker.position.lat + "," + address.marker.position.lng;
    this.weddingHallPlaceId = address.placeId;
    this.scrollToNextButton();
  }

  onNext() {
    this.$store.commit("setChurchAddress", {
      address: this.churchAddress,
      latLon: this.churchLatLon,
      placeId: this.churchPlaceId,
      userName: this.churchName,
    });

    this.$store.commit("setWeddingHallAddress", {
      address: this.weddingHallAddress,
      latLon: this.weddingHallLatLon,
      placeId: this.weddingHallPlaceId,
      userName: this.weddingHallName,
    });

    if (this.hourUnknown) {
      this.$store.commit("setWeddingTime", "");
    } else {
      this.$store.commit(
        "setWeddingTime",
        this.weddingTimeHour + ":" + this.weddingTimeMinute
      );
    }
    this.$router.push({ path: "/onboarding/final" });
  }

  scrollToNextButton() {
    const el = this.$el.querySelector(".nextButton");

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
       this.$nextTick(() => {
          el.scrollIntoView();
      });
      
    }
  }
}








































































import { Component, Watch } from "vue-property-decorator";
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";

@Component
export default class OnboardDetailsComponent extends NaWiekiComponentBase {
  public showNext: boolean = false;
  public rules: any = {
    required: (value: string) => !!value || "Pole wymagane.",
    email: (value: string) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !value || pattern.test(value) || "Niepoprawny adres e-mail.";
    },
    nameOnly: (value: string) => {
      return !/\s/.test(value.trim()) || "Podaj jedynie imię.";
    },
  };

  public refs = this.$refs as any;
  public brideName: string = "";
  public brideEmail: string = "";
  public bridePhone: string = "";
  public brideEmailReadonly = false;

  public groomName: string = "";
  public groomEmail: string = "";
  public groomPhone: string = "";
  public groomEmailReadonly = false;

  mounted() {
    if (!this.$store.state.website.weddingDate) {
      this.$router.push({ path: "/onboarding/start" });
      return;
    }
    if (!!this.$store.state.website.brideEmail) {
      this.brideEmail = this.$store.state.website.brideEmail;
      this.brideEmailReadonly = true;
    }
    if (!!this.$store.state.website.groomEmail) {
      this.groomEmail = this.$store.state.website.groomEmail;
      this.groomEmailReadonly = true;
    }
  }

  @Watch("brideName")
  @Watch("brideEmail")
  @Watch("bridePhone")
  @Watch("groomName")
  @Watch("groomEmail")
  @Watch("groomPhone")
  onDataChanged(newVal: string, oldVal: string) {
    if (!!this.brideName && !!this.groomName) {
      this.showNext = true;
      this.scrollToNextButton();
    } else {
      this.showNext = false;
    }
  }

  onNext() {
    if (!this.refs.form.validate()) {
      return;
    }
    this.$store.commit("setBrideName", this.brideName.trim());
    this.$store.commit("setBrideEmail", this.brideEmail.trim());
    this.$store.commit("setBridePhone", this.bridePhone.trim());

    this.$store.commit("setGroomName", this.groomName.trim());
    this.$store.commit("setGroomEmail", this.groomEmail.trim());
    this.$store.commit("setGroomPhone", this.groomPhone.trim());
    this.$router.push({ path: "/onboarding/event" });
  }

  scrollToNextButton() {
    const el = this.$el.querySelector(".nextButton");

    if (el) {
      // Use el.scrollIntoView() to instantly scroll to the element
      this.$nextTick(() => {
        el.scrollIntoView();
      });
    }
  }
}

import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppConsts from '@/shared/application/nawieki';
import { mapState } from 'vuex'
import { availableDomains } from '../../../../stores/app-store';
import AddressHelper from '@/shared/helpers/address-helper';

@Component({
    components: {
        Panel: require('@/main/components/home/panels/panel.vue').default
    },
    computed: {
        ...mapState({
            website: 'website',
            newWebsite: 'newWebsite'
        }),
        brideName: {
            get() {
                return this.$store.state.website.brideName
            },
            set(value) {

                this.$store.commit('setBrideName', value.trim());
                (this as any).fillWebsiteAddress();
            }
        },
        groomName: {
            get() {
                return this.$store.state.website.groomName
            },
            set(value) {
                this.$store.commit('setGroomName', value.trim());
                (this as any).fillWebsiteAddress();
            }
        },
        brideEmail: {
            get() {
                if (this.$data.brideEmailNotNull == null && this.$store.state.website.brideEmail !== undefined) {
                    this.$data.brideEmailNotNull = !!this.$store.state.website.brideEmail;
                }
                return this.$store.state.website.brideEmail;
            },
            set(value) {
                this.$store.commit('setBrideEmail', value)
            }
        },
        groomEmail: {
            get() {
                if (this.$data.groomEmailNotNull == null && this.$store.state.website.groomEmail !== undefined) {
                    this.$data.groomEmailNotNull = !!this.$store.state.website.groomEmail;
                }
                return this.$store.state.website.groomEmail;
            },
            set(value) {
                this.$store.commit('setGroomEmail', value)
            }
        },
        websiteAddress: {
            get() {
                return this.$store.state.website.websiteAddress
            },
            set(value) {
                var trimmedValue = value.trim();
                (this as any).checkAddressReserved(trimmedValue, (this as any).domain);
                this.$store.commit('setWebsiteAddress', trimmedValue)
            }
        },
        domain: {
            get() {
                return this.$store.state.website.domain;
            },
            set(value) {
                (this as any).checkAddressReserved((this as any).websiteAddress, value);
                this.$store.commit('setDomain', value)
            }
        }
    },
})
export default class BasicInfoPanelComponent extends NaWiekiComponentBase {
    model!: IWebsiteModel;
    brideEmailNotNull: boolean | null = null;
    groomEmailNotNull: boolean | null = null;
    isAddressAllowed = true;

    private domains: string[] = availableDomains;
    // private defaultDomainSelected: string = "nawieki.pl";

    nameRules: Array<(arg: string) => string | boolean> = [
        v => !!v || 'Pole jest wymagane',
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ];

    rules = {
        required: (value: string) => !!value || 'Pole jest wymagane.',
        counter: (value: string) => value.length <= 20 || 'Max 20 characters',
        nameOnly: (value: string) => {
            return (!!value && !/\s/.test(value.trim())) || "Podaj jedynie imię.";
          },
        email: (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Niepoprawny e-mail.'
        },
        noWhitespaces: (value: string) => {
            return !/\s/.test(value) || "Nie używaj spacji w adresie.";
          },
    };

    fillWebsiteAddress() {
        var address = AddressHelper.buildWebsiteAddress(this.$store);
        this.$store.commit('setWebsiteAddress', address);
    }

    checkAddressReserved(address: string, domain: string) {
        AddressHelper.checkAddressReserved(address, domain, this.NaWiekiService).then((response) => {
            this.isAddressAllowed = response.content;
        });
    }
}
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import DietHelper from '@/shared/helpers/diet-helper';
import { IGuestModel } from '@/shared/models/guest-model';
import { Component } from 'vue-property-decorator';

@Component({
    computed: {
        childAge: {
            get() {
                return this.$store.state.website.childAge;
            }
        },
        teenAge: {
            get() {
                return this.$store.state.website.teenAge;
            }
        },
        ageEnabled: {
            get() {
                return this.$store.state.website.childAge !== null || this.$store.state.website.teenAge != null;
            }
        },
        dietEnabled: {
            get() {
                return this.$store.state.website.selectedDiets?.length > 0;
            }
        },
        isVaccinatedQuestion: {
            get() {
                return this.$store.state.website.isVaccinatedQuestion;
            }
        },
        vaccinationDetailsQuestion: {
            get() {
                return this.$store.state.website.vaccinationDetailsQuestion;
            }
        },
        aftermathPresenceQuestion: {
            get() {
                return this.$store.state.website.aftermathPresenceQuestion;
            }
        },
        accommodationNeededQuestion: {
            get() {
                return this.$store.state.website.accommodationNeededQuestion;
            }
        },
        guestQuestionsPermissionGranted: {
            get() {
                return (this as any).NaWieki.auth.isGranted("Permissions_GuestsQuestions");
            }
        }
    }
})
export default class GuestsListComponent extends NaWiekiComponentBase {
    dialog: boolean = false;
    dialogStatus: boolean = false;
    dialogDelete: boolean = false;
    dialogUnknown: boolean = false;
    loading: boolean = false;
    showGroups: boolean = false;
    headers = [
        {
            text: 'Imię',
            align: 'start',
            value: 'firstName',
        },
        { text: 'Nazwisko', value: 'lastName' },
        { text: 'Numer telefonu', value: 'phoneNumber' },
        { text: 'Adres e-mail', value: 'email' },
        { text: 'Status', value: 'status' },
        { text: 'Czas odpowiedzi', value: 'respondedDate' },
        { text: 'Akcje', value: 'actions', sortable: false },
    ];
    guests: Array<IGuestModel> = [];
    editedStatus = 0;
    editedIndex = -1;
    editedItem: IGuestModel = {
        id: null,
        firstName: "",
        lastName: "",
        email: "",
        isUnknown: false,
        phoneNumber: null,
        status: 0,
        respondedDate: null,
        isChild: false,
        isTeen: false,
        selectedDiet: null,
        isAttendingAftermath: false,
        isUsingAccommodation: false,
        isVaccinated: false,
        vaccinationDetails: null,
        companions: [],
        isCompanion: false,
        expanded: true
    };
    defaultItem: IGuestModel = {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        isUnknown: false,
        phoneNumber: null,
        status: 0,
        respondedDate: null,
        isChild: false,
        isTeen: false,
        selectedDiet: null,
        isAttendingAftermath: false,
        isUsingAccommodation: false,
        isVaccinated: false,
        vaccinationDetails: null,
        companions: [],
        isCompanion: false,
        expanded: true
    };

    statusesDict = [{ id: 0, name: "Zaproszono" }, { id: 1, name: "Potwierdzono" }, { id: 2, name: "Odrzucono" }];
    statuses = this.statusesDict.map(x => x.name);

    ageDict = [{ id: 0, name: "Dorosły" }] as any[];
    editedAge = 0;

    dietDict = [{ id: 0, name: "(brak)" }] as any[];
    editedDiet = 0;

    aftermathDict = [{ id: false, name: "Nieobecna/y" }, { id: true, name: "Obecna/y" }] as any[];
    editedAftermathPresence = 0;

    isVaccinatedDict = [{ id: true, name: "Zaszczepiona/y" }, { id: false, name: "Niezaszczepiona/y" }] as any[];

    yesNoDict = [{ id: true, name: "Tak" }, { id: false, name: "Nie" }] as any[];


    expanded: Array<IGuestModel> = [];

    async mounted() {
        await this.$store.dispatch('loadWebsiteIfNeeded');
        this.loading = true;
        if (Object.keys(this.$store.state.website).length === 0) {
            this.$store.dispatch('loadWebsite').then((res) => {
                this.setHeaders();
                this.getGuests();
                this.fillDicts();
                this.loading = false;
            });
        } else {
            this.setHeaders();
            this.getGuests();
            this.fillDicts();
            this.loading = false;
        }


    }

    fillDicts() {
        if (this.$store.state.website.childAge !== null) {
            this.ageDict.push({ id: 1, name: `Dziecko do ${this.$store.state.website.childAge} lat` });
        }
        if (this.$store.state.website.teenAge !== null) {
            this.ageDict.push({ id: 2, name: `Młodzież do ${this.$store.state.website.teenAge} lat` });
        }

        var i: number = 1;
        this.$store.state.website.selectedDiets.forEach((element: number) => {
            this.dietDict.push({ id: element, name: DietHelper.getDietName(element) });
        });
    }

    get formTitle() {
        return this.editedIndex === -1 ? 'Nowy gość' : 'Edytuj gościa'
    };

    get knownGuests() {
        if (this.showGroups) {
            return this.guests.filter(x => !x.isUnknown && !x.isCompanion);
        }
        return this.guests.filter(x => !x.isUnknown);
    }

    get unknownGuests() {
        return this.guests.filter(x => x.isUnknown);
    }

    setHeaders() {
        if ((this as any).guestQuestionsPermissionGranted && (this.$store.state.website.childAge != null || this.$store.state.website.teenAge != null)) {
            this.headers.splice(this.headers.length - 2, 0, { text: "Wiek", value: "age" });
        }
        if ((this as any).guestQuestionsPermissionGranted && (this.$store.state.website.selectedDiets.length > 0)) {
            this.headers.splice(this.headers.length - 2, 0, { text: "Dieta", value: "selectedDiet" });
        }
        if ((this as any).guestQuestionsPermissionGranted && ((this as any).isVaccinatedQuestion || (this as any).vaccinationDetailsQuestion)) {
            this.headers.splice(this.headers.length - 2, 0, { text: "Szczepienie", value: "vaccination" });
        }
        if ((this as any).guestQuestionsPermissionGranted && ((this as any).aftermathPresenceQuestion)) {
            this.headers.splice(this.headers.length - 2, 0, { text: "Poprawiny", value: "aftermath" });
        }
        if ((this as any).guestQuestionsPermissionGranted && ((this as any).accommodationNeededQuestion)) {
            this.headers.splice(this.headers.length - 2, 0, { text: "Nocleg", value: "accommodation" });
        }
    }

    getGuests(): void {
        this.loading = true;
        this.NaWiekiService.get<any>('/api/guest', false).then((response) => {
            this.loading = false;
            this.guests = response.content as Array<IGuestModel>;
            if (this.guests.some(x => x.isUnknown == true)) {
                this.dialogUnknown = true;
            }
        });
    }

    editItem(item: IGuestModel, onlyStatus = false) {
        this.editedIndex = this.guests.indexOf(item as never)
        this.editedItem = Object.assign({}, item);

        this.editedAge = item.isChild == true ? 1 : item.isTeen ? 2 : 0;
        if (item.selectedDiet == null) {
            this.editedDiet = 0;
        } else {
            var filterResults = this.dietDict.filter(x => x.id == item.selectedDiet);
            if (filterResults.length !== 1) {
                console.error("Blad przy wyborze diety. Pewnie zmienily sie dostepne diety a gosc ma ustawiona juz niemozliwa do wybrania diete")
                return;
            }
            this.editedDiet = filterResults[0].id;
        }
        if (onlyStatus) {
            this.dialogStatus = true
        } else {
            this.dialog = true
        }
    }

    deleteItem(item: any) {
        this.editedIndex = this.guests.indexOf(item as never)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
    }

    deleteItemConfirm() {
        this.NaWiekiService.delete('/api/guest', this.editedItem).then((response) => {
            this.guests.splice(this.editedIndex, 1)
            this.closeDelete()
        });

    }

    close() {
        this.dialog = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    }

    closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    }

    closeStatus() {
        this.dialogStatus = false
        this.$nextTick(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.editedIndex = -1
        })
    }

    save() {
        if (this.editedAge == 1) {
            this.editedItem.isChild = true;
        }
        else if (this.editedAge == 2) {
            this.editedItem.isTeen = true;
        }
        else {
            this.editedItem.isChild = false;
            this.editedItem.isTeen = false;
        }

        if (this.editedDiet == 0) {
            this.editedItem.selectedDiet = null;
        } else {
            this.editedItem.selectedDiet = this.editedDiet;
        }

        if (this.editedIndex > -1) {
            Object.assign(this.guests[this.editedIndex], this.editedItem)
        }
        if (this.editedItem.id === "") {
            this.editedItem.id = null;
        }
        this.NaWiekiService.post<any>('/api/guest', this.editedItem).then((response) => {
            this.getGuests();
            this.close();
            this.closeStatus();
        });


    }

    getStatusColor(status: number) {
        switch (status) {
            case 0: return "blue";
            case 1: return "green";
            case 2: return "red";
        }
    }

    getStatusLabel(status: number) {
        switch (status) {
            case 0: return "Zaproszono";
            case 1: return "Potwierdzono";
            case 2: return "Odrzucono";
        }
    }

    getDietName(diet: number): string {
        return DietHelper.getDietName(diet);
    }


    getKeyByValue(object: any, value: unknown): number {
        return parseInt(Object.keys(object).find(key => object[key] === value)!);
    }

    getHumanFriendlyDate(date: string) {
        if (date === null) {
            return "";
        }
        return new Date(date).toLocaleString();
    }

    confirmUnknownGuest(guest: IGuestModel) {
        this.NaWiekiService.post<any>('/api/guest/confirmKnown', { "guestId": guest.id, "isKnown": true }).then((response) => {

        });
        this.guests.find(x => x.id == guest.id)!.isUnknown = false;
        if (this.unknownGuests.length == 0) {
            this.dialogUnknown = false;
        }
    }

    removeUnknownGuest(guest: IGuestModel) {
        this.NaWiekiService.post<any>('/api/guest/confirmKnown', { "guestId": guest.id, "isKnown": false }).then((response) => {

        });
        const index = this.guests.indexOf(guest);
        if (index > -1) {
            this.guests.splice(index, 1);
        }
        if (this.unknownGuests.length == 0) {
            this.dialogUnknown = false;
        }
    }

    showGroupsClicked(val: boolean) {
        if (val) {
            this.expanded = this.guests.filter(x => x.companions.length >0).sort((x, y) => y.lastName.localeCompare(x.lastName));
        } else {
            this.expanded = [];
        }
    }

    expandCompanions(slotData: any) {
        const indexExpanded = this.expanded.findIndex(i => i === slotData.item);
        if (indexExpanded > -1) {
          this.expanded.splice(indexExpanded, 1)
        } else {
          this.expanded.push(slotData.item);
        }
      }
}
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Watch } from 'vue-property-decorator';
import Guid from '@/shared/helpers/guid-helper';

@Component
export default class UserListComponent extends NaWiekiComponentBase {
    refs = this.$refs as any;
    loading = true;
    options = {};
    search = '';
    dialog = false;
    formTitle = '';
    errors: INameValueDto[] = [];
    allRoles: IRoleDto[] = [];
    isEdit = false;
    selectAll = false;

    get headers() {
        return [
            { text: this.$t('Email'), value: 'email' },
            { text: "Data stworzenia", value: 'createdDate' },
            { text: "Adres strony", value: 'websiteAddress' },
            { text: this.$t('Actions'), value: 'actions', sortable: false }
        ];
    }

    createOrUpdateUserInput = {
        grantedRoleIds: [],
        user: {} as IUserDto
    } as ICreateOrUpdateUserInput;

    pagedListOfUserListDto: IPagedList<IPagedListInput> = {
        totalCount: 0,
        items: []
    };

    @Watch('options')
    onPaginationChanged() {
        this.getUsers();
    }

    @Watch('search')
    onSearchChanged() {
        this.getUsers();
    }

    mounted() {
        this.getUsers();
    }

    editUser(id: string) {
        this.formTitle = id ? this.$t('EditUser').toString() : this.$t('NewUser').toString();
        this.isEdit = id ? true : false;
        this.errors = [];
        this.NaWiekiService.get<IGetUserForCreateOrUpdateOutput>('/api/users/' + (id ? id : Guid.empty))
            .then((response) => {
                const result = response.content as IGetUserForCreateOrUpdateOutput;
                this.allRoles = result.allRoles;
                this.createOrUpdateUserInput = {
                    grantedRoleIds: result.grantedRoleIds,
                    user: result.user
                };
                this.dialog = true;
            });
    }

    deleteUser(id: string) {
        this.swalConfirm(this.$t('AreYouSureToDelete').toString())
            .then((result) => {
                if (result.value) {
                    this.NaWiekiService.delete('/api/users?id=' + id)
                        .then((response) => {
                            if (!response.isError) {
                                this.swalToast(2000, 'success', this.$t('Successful').toString());
                                this.getUsers();
                            } else {
                                var errorText = "";
                                response.errors.forEach(error => {
                                    errorText += this.$t(error.name) + '<br>';
                                });

                                this.swalAlert('error', errorText);
                            }
                        });
                }
            });
    }

    save() {
        if (this.refs.form.validate()) {
            this.errors = [];
            this.NaWiekiService.postOrPut<void>('/api/users',
                this.createOrUpdateUserInput as ICreateOrUpdateUserInput,
                this.createOrUpdateUserInput.user.id)
                .then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.$t('Successful').toString());
                        this.dialog = false;
                        this.getUsers();
                    } else {
                        this.errors = response.errors;
                    }
                });
        }
    }

    getUsers() {
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
        const userListInput: IPagedListInput = {
            filter: this.search,
            pageIndex: page - 1,
            pageSize: itemsPerPage
        };

        if (sortBy.length > 0 && sortBy[0]) {
            userListInput.sortBy = sortBy + ((sortDesc.length > 0 && sortDesc[0]) ? ' desc' : '');
        }

        const query = '?' + this.queryString.stringify(userListInput as any);
        this.NaWiekiService.get<IPagedList<IPagedListInput>>('/api/users' + query, false).then((response) => {
            this.pagedListOfUserListDto = response.content as IPagedList<IPagedListInput>;
            this.loading = false;
        });
    }

    selectAllRoles() {
        this.createOrUpdateUserInput.grantedRoleIds = [];
        if (this.selectAll) {
            this.createOrUpdateUserInput.grantedRoleIds = ((this.allRoles.map((roles) => roles.id)) as string[]);
        }
    }

    loginAs(email: string) {

        this.NaWiekiService.post<any>('/api/loginas/' + email, {})
            .then((response) => {
                this.authStore.removeToken();
                if (!response.isError) {
                    this.authStore.setToken(response.content.token);
                    this.$router.push({ path: '/' });
                } else {
                    this.errors = response.errors;
                }
            });
    }
}
export default class LanguageStore {
    public static storageKey: string = 'language';

    public static getLanguage(): ILanguageDto {
        return JSON.parse(localStorage.getItem(LanguageStore.storageKey) as string) ||
            ({ languageCode: 'pl', languageName: 'Polski' } as ILanguageDto);
    }

    public static setLanguage(input: ILanguageDto) {
        localStorage.setItem(LanguageStore.storageKey, JSON.stringify(input));
    }

    public static removeLanguage(): void {
        localStorage.removeItem(LanguageStore.storageKey);
    }

    public static getAvailableLanguages(): Array<ILanguageDto> {
        return [
            { languageName: "angielski", languageCode: "us" },
            { languageName: "białoruski", languageCode: "by" },
            { languageName: "chiński", languageCode: "cn" },
            { languageName: "chorwacki", languageCode: "hr" },
            { languageName: "czeski", languageCode: "cz" },
            { languageName: "duński", languageCode: "dk" },
            { languageName: "estoński", languageCode: "ee" },
            { languageName: "francuski", languageCode: "fr" },
            { languageName: "grecki", languageCode: "gr" },
            { languageName: "hiszpański", languageCode: "es" },
            { languageName: "japoński", languageCode: "jp" },
            { languageName: "koreański", languageCode: "kr" },
            { languageName: "litewski", languageCode: "lt" },
            { languageName: "łotewski", languageCode: "lv" },
            { languageName: "niemiecki", languageCode: "de" },
            { languageName: "norweski", languageCode: "no" },
            { languageName: "portugalski", languageCode: "pt" },
            { languageName: "rosyjski", languageCode: "ru" },
            { languageName: "rumuński", languageCode: "ro" },
            { languageName: "słowacki", languageCode: "sk" },
            { languageName: "szwedzki", languageCode: "se" },
            { languageName: "tajski", languageCode: "th" },
            { languageName: "turecki", languageCode: "tr" },
            { languageName: "ukraiński", languageCode: "ua" },
            { languageName: "wietnamski", languageCode: "vn" },
            { languageName: "węgierski", languageCode: "hu" },
            { languageName: "włoski", languageCode: "it" }
        ];
    }
}
import './assets/sass/site.css';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n';
import LanguageStore from '@/stores/language-store';
import Vuex from 'vuex'
import { appStore } from './stores/app-store'

Vue.use(VueI18n);
Vue.use(Vuex); 

const VueGooglePlaces = require('vue-google-places');
Vue.use(VueGooglePlaces)


const VueGoogleMaps = require('gmap-vue');  
Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDHYEWhjdL-g_jZrPZzGWdUsr0LpvuVcaM',
      libraries: 'places',
    }
  });

const locales = {
    en: require('@/assets/localizations/en.json'),
    tr: require('@/assets/localizations/tr.json'),
    pl: require('@/assets/localizations/pl.json')
};

const i18n = new VueI18n({
    locale: LanguageStore.getLanguage().languageCode,
    fallbackLocale: 'pl',
    messages: locales
});

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    vuetify,
    store: appStore,
    render: h => h(App)
}).$mount('#app');

import NaWiekiService from '@/shared/application/nawieki-service-proxy';
import AuthStore from '@/stores/auth-store';
import { appConst } from "../../settings";

const NaWieki = {
    baseApiUrl: appConst.webApiUrl,
    baseClientUrl: appConst.webClientUrl,
    isLoading: false,
    loadingBlocked: false,
    isError: false,
    appVersion: '1.0.0',
    auth: {
        grantedPermissions: [] as IPermissionDto[],
        isGranted(permissionName: string) {
            return this.grantedPermissions.filter((p) => p.name == permissionName).length > 0;
        },
        removeProps() {
            this.grantedPermissions = [];
        },
        fillProps() {
            const naWiekiService = new NaWiekiService();
            naWiekiService.get<IPermissionDto[]>(
                '/api/permissions?userNameOrEmail=' + encodeURIComponent(AuthStore.getTokenData().sub)
            ).then((response) => {
                if (!response.isError) {
                    this.grantedPermissions = response.content as IPermissionDto[];
                }
            });
        }
    }
};

export default NaWieki;
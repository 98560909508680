import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { Component } from "vue-property-decorator";

@Component
export default class ClipboardCopierComponent extends NaWiekiComponentBase {
    public showTooltip = false;
    clicked() {
        var text = (this.$slots as any).default[0].text;
        const input = document.createElement('input')
        input.style.position = 'absolute'
        input.style.top = '-9999px'
        input.value = text
        document.body.appendChild(input)
        input.select()
        document.execCommand('copy')
        input.parentNode?.removeChild(input)
        this.showTooltip = true;

        setTimeout(() => this.showTooltip = false, 2000);
    }
}
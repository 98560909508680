import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component } from 'vue-property-decorator';

@Component({
    components: {
        AsideMenu: require('@/main/components/menu/aside-menu/aside-menu.vue').default,
        TopMenu: require('@/main/components/menu/top-menu/top-menu.vue').default
    }
})
export default class MainLayoutComponent extends NaWiekiComponentBase {
    public created() {
        this.NaWieki.auth.fillProps();
    }
}
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { ITranslationModel } from "@/shared/models/translation-model";
import LanguageStore from "@/stores/language-store";
import Component from "vue-class-component";

@Component({
    computed: {
        selectedLang: {
            get() {
                var code = this.$route.params.code;
                return LanguageStore.getAvailableLanguages().find(x => x.languageCode == code);
            }
        },
        id: {
            get() {
                return this.$route.params.id;
            }
        },
        website: {
            get() {
                return this.$store.state.website;
            }
        }
    }
})
export default class TranslationComponent extends NaWiekiComponentBase {

    private model = {} as ITranslationModel;

    async mounted() {
        await this.$store.dispatch('loadWebsiteIfNeeded');  
        if(this.$route.params.id) {
            await this.$store.dispatch('loadTranslations');
            this.model = this.$store.state.translations.find((x: ITranslationModel) => x.id == this.$route.params.id);
        } else {
            this.model = {
                brideName: (this as any).website?.brideName,
                groomName: (this as any).website?.groomName,
                contactPersonName: (this as any).website?.contactPersonName,
                welcomeText: (this as any).website?.welcomeText,
                giftsDescription : (this as any).website?.giftsDescription,
                preWeddingEventName : (this as any).website?.preWeddingEventName,
                postWeddingEventName : (this as any).website?.postWeddingEventName,
                languageCode: (this as any).$route.params.code
            } as ITranslationModel;
        }
    }

    async saveTranslation() {
        this.NaWieki.isLoading = true;
        await this.$store.dispatch('saveTranslation', (this as any).model);
        await this.$store.dispatch('saveWebsite');
        this.NaWieki.isLoading = false;
        this.$router.push({path: "/main/translations"});
    }
}
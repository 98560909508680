import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import SmsHelper from '@/shared/helpers/sms-helper';
import { ICommunicationGuestModel, ICommunicationModel } from '@/shared/models/communication-model';
import { GuestStatus } from '@/shared/models/guest-status';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Route } from "vue-router"

@Component({
  computed: {
    smsCount: {
      get() {
        return SmsHelper.getSmsCount((this as any).model.content);
      }
    },
    remainingChars: {
      get() {
        return SmsHelper.getRemainingChars((this as any).model.content);
      }
    },
    currentCommunicationSmsUsage: {
      get() {
        if ((this as any).selectedType == 0) {
          return (this as any).smsCount * (this as any).allInvitedAndConfirmedGuests?.length ?? 0;
        } else if ((this as any).selectedType == 1) {
          return (this as any).smsCount * (this as any).allInvitedAndConfirmedGuests?.length ?? 0;
        } else if ((this as any).selectedType == 2) {
          return (this as any).smsCount * (this as any).allInvitedGuests?.length ?? 0;
        }
      }
    },
    allInvitedAndConfirmedGuests: {
      get() {
        return (this as any).guests?.filter((x: ICommunicationGuestModel) => x.smsSelected);
      },
      set(value) {
        (this as any).guests = JSON.parse( JSON.stringify( value ) );
      }
    },
    allInvitedGuests: {
      get() {
        return (this as any).guests?.filter((x: ICommunicationGuestModel) => x.smsSelected && x.status == GuestStatus.Invited);
      }
    }
  }
})
export default class CommunicationComponent extends NaWiekiComponentBase {

  public model = {} as ICommunicationModel;
  menu = false;
  timeMenu = false;
  dialog: boolean = false;
  smsRemaining: number = 0;
  validationError: boolean = false;

  headers = [
    {
      text: 'Imię',
      align: 'start',
      value: 'firstName',
    },
    { text: 'Nazwisko', value: 'lastName' },
    { text: 'Numer telefonu', value: 'phoneNumber' },
    { text: 'Adres e-mail', value: 'email' },
    { text: 'Wysyłka SMS', value: 'smsSelected' },
    { text: 'Wysyłka e-mail', value: 'emailSelected' }
  ];

  types: Array<object> = [
    { text: 'Wyślij wiadomość do wszystkich potwierdzonych oraz zaproszonych gości', value: 0 },
    { text: 'Wyślij wiadomość do wszystkich zaproszonych gości, którzy jeszcze nie potwierdzili obecności', value: 2 },
    { text: 'Wybierz gości, do których chcesz wysłać wiadomość', value: 1 }
  ];

  selectedType: number = 0;

  guests: Array<ICommunicationGuestModel> = [];

  currentSelectedSms: number = 0;
  plannedSmsUsage: number = 0;

  constructor() {
    super();
  }

  mounted() {

    this.getWebsite();
    if (this.$route.params.id) {
      this.getCommunication(this.$route.params.id);
    } else {
      this.getGuests();
    }
    this.getPlannedSmsUsage(this.$route.params.id);
  }

  getGuests(): void {
    this.NaWiekiService.get<any>('/api/guest', false).then((response) => {
      this.guests = response.content as Array<ICommunicationGuestModel>;

      this.guests = this.guests.filter(x => x.status == GuestStatus.Confirmed || x.status == GuestStatus.Invited);
      this.guests.forEach(x => {
        if (x.phoneNumber) {
          x.smsSelected = true;
          this.currentSelectedSms++;
        } else if (x.email) {
          x.emailSelected = true;
        }
      });
    });
  }

  getWebsite() {
    this.NaWiekiService.get<any>('/api/website', false).then((response) => {
      this.smsRemaining = response.content.smsRemaining;
    });
  }

  getCommunication(id: string) {
    this.NaWiekiService.get<any>('/api/communication/' + id, false).then((response) => {
      this.model = response.content;
      this.guests = response.content.guests;
      this.selectedType = this.model.type;

      this.NaWiekiService.get<any>('/api/guest', false).then((response) => {
        let allGuests = response.content as Array<ICommunicationGuestModel>;
        allGuests = allGuests.filter(x => x.status === 1);
        let remainingGuests = this.inSecondOnly(this.guests, allGuests);
        remainingGuests.forEach(x => this.guests.push(x));
        this.smsSelectionChanged();
      });
    });
  }

  getPlannedSmsUsage(id: string | null) {
    let url = '/api/communication/smsUsage';
    if (id) {
      url = '/api/communication/smsUsage/' + id;
    }
    this.NaWiekiService.get<any>(url, false).then((response) => {
      this.plannedSmsUsage = response.content;
    });
  }

  contentChanged() {
    if (SmsHelper.isBeyondLimit(this.model.content)) {
      this.model.content = this.model.content.slice(0, -1);
    }
  }

  get getToday() {
    return new Date().toJSON();
  }

  savePlannedDate(date: Date) {
    this.menu = false;
    (this.$refs.menu as any).save(date);
    this.model.plannedDate = date;
  }

  savePlannedTime(time: string) {
    this.timeMenu = false;
    (this.$refs.timeMenu as any).save(time);
    this.model.plannedTime = time;
  }

  getStatusColor(status: number) {
    switch (status) {
      case 0: return "blue";
      case 1: return "green";
      case 2: return "red";
    }
  }

  getStatusLabel(status: number) {
    switch (status) {
      case 0: return "Zaproszono";
      case 1: return "Potwierdzono";
      case 2: return "Odrzucono";
    }
  }

  smsSelectionChanged(item: ICommunicationGuestModel | null = null) {
    this.currentSelectedSms = this.guests.filter(x => x.smsSelected).length;
    (this as any).allInvitedAndConfirmedGuests = this.guests;
  }

  saveCommunication() {
    if(!this.model.content || !this.model.name || !this.model.plannedDate || !this.model.plannedTime) {
      this.validationError = true;
      return;
    } else {
      this.validationError = false;
    }
    this.model.type = this.selectedType;
    if (this.model.type === 1) {
      this.model.guests = this.guests;
    }
    this.NaWiekiService.post<any>('/api/communication', this.model).then((response) => {
      this.$router.push('/main/communications')
    });
  }

  get getHint() {
    if (this.selectedType == 0) {
      return "Lista gości, do których skierowana będzie wysyłka zostanie skomponowana spośród zaproszonych gości w dniu wysyłki. Pamiętaj, by mieć w zapasie odpowiednią ilość SMS, by zrealizować wysyłkę. Aktualnie posiadasz do wykorzystania <b>" + this.smsRemaining + "</b> SMS. <a href=\"#\">Dokup więcej SMS</a>"
    } else {
      return "";
    }
  }

  get getContentHint() {

    var isPolishDiacriticsUsed = SmsHelper.containsPolishDiacritics(this.model.content);

    var msg = `${(this as any).smsCount} SMSy, ${(this as any).remainingChars} znaków do końca kolejnego. Używanie znaków specjalnych bądź polskich znaków diakrytycznych (ą, ł, ć, ...) powoduje, że limit znaków w jednym SMS zmienia się na 70. Dodatkowo, znaki <b>^ { } [ ] ~ \\ | €</b> liczone są podwójnie (w przypadku wysyłania wiadomości bez znaków specjalnych) ze względu na wymogi specyfikacji GSM.`;

    if (isPolishDiacriticsUsed) {
      msg += "<br /><br /><span class=\"red--text\">Użyto polskich znaków diakrytycznych!</span>";
    }
    if (SmsHelper.isContentLimitReached(this.model.content) || SmsHelper.isBeyondLimit(this.model.content)) {

      msg += "<br /><br /><span class=\"red--text\"><b>Osiągnięto limit SMS na jedną komunikację!</b></span>";
    }

    return msg;
  }


  // Generic helper function that can be used for the three operations:        
  operation = (list1: Array<any>, list2: Array<any>, isUnion = false) =>
    list1.filter(a => isUnion === list2.some(b => a.id === b.id));

  // Following functions are to be used:
  inBoth = (list1: Array<any>, list2: Array<any>) => this.operation(list1, list2, true);
  inFirstOnly = this.operation;
  inSecondOnly = (list1: Array<any>, list2: Array<any>) => this.inFirstOnly(list2, list1);
}
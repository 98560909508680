import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { IMembershipStatusModel } from "@/shared/models/membership-status-model";
import { MembershipType } from "@/shared/models/membership-type";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
    computed: {
        ...mapState({
            membership: "membership"
        }),
        membershipInfo: {
            get() {
                if (Object.keys((this as any).membership).length === 0) {
                    return "";
                }
                var membership = (this as any).membership as IMembershipStatusModel;
                if (membership.isActive && membership.membership != MembershipType.Basic) {
                    return `Pakiet: ` + (this as any).membershipsLabels.find((x: any) => x.type == membership.membership).value;

                }


            }
        },
    },
})
export default class MembershipInfoComponent extends NaWiekiComponentBase {
    public membershipPanelShown = false;
    public membership!: IMembershipStatusModel;
    public isLight = false;


    public membershipsLabels = [
        { type: MembershipType.Silver, value: "🤍 Srebrny" },
        { type: MembershipType.Gold, value: "👑 Złoty" },
        { type: MembershipType.Diamond, value: "💎 Diamentowy" },
    ];
}


import AuthStore from '@/stores/auth-store';
import AppConsts from '@/shared/application/nawieki';
import NaWieki from '@/shared/application/nawieki';
import Guid from '@/shared/helpers/guid-helper';

export default class NaWiekiService {
    private static request<T>(method: string, url: string, data: any | File | string = '', loadingEnabled = true):
        Promise<IRestResponseDto<T>> {
        
        let isBadRequest = false;
        let isServerError = false;
        let body = data === '' ? null : data;
        const headers: { [key: string]: string } = {
            Authorization: `Bearer ${AuthStore.getToken()}`,
        };

        if(data instanceof File) {
            
            let formData = new FormData();
            formData.append("file", data, data.name);
            body = formData;
        } else if (data instanceof Array && data[0] instanceof File) {
            let formData = new FormData();
            data.forEach(element => {
                formData.append("file", element, element.name);
            });
            body = formData;
        } else if(data) {
            headers["Content-Type"] = "application/json";
            body = JSON.stringify(data);
        }

        if (loadingEnabled) {
            NaWieki.isLoading = true;
        }

        return fetch(AppConsts.baseApiUrl + url,
            ({
                method,
                headers,
                body
            }) as any)
            .then((response: any) => {
                isBadRequest = !response.ok;
                if(response.status === 500) {
                    isServerError = true;
                }
                if (response.status === 401) {
                    AuthStore.removeToken();
                    return { errorMessage: 'Unauthorized request' };
                }

                if(response.status == 403) {
                    throw "Unauthrorized";
                }

                return response.text();
            })
            .then((responseContent: any) => {
                if(isServerError) {
                    NaWieki.isError = true;
                }
                
                let content: any;
                
                try {
                    content = JSON.parse(responseContent);
                } catch (err) {
                    content = responseContent;
                }

                const response = {
                    isError: isBadRequest,
                    errors: isBadRequest ? content : null,
                    content: isBadRequest ? null : content
                } as IRestResponseDto<T>;

                return response;
            })
            .finally(() => {
                NaWieki.isLoading = false;
            });
    }

    get<T>(url: string, loadingEnabled = true): Promise<IRestResponseDto<T>> {
        return NaWiekiService.request<T>('GET', url, '', loadingEnabled);
    }

    delete(url: string, data: any | string | undefined = undefined): Promise<IRestResponseDto<void>> {
        return NaWiekiService.request<void>('DELETE', url, data);
    }

    put<T>(url: string, data: any | File | string): Promise<IRestResponseDto<T>> {
        return NaWiekiService.request<T>('PUT', url, data);
    }

    post<T>(url: string, data: any | string): Promise<IRestResponseDto<T>> {
        return NaWiekiService.request<T>('POST', url, data);
    }

    postOrPut<T>(url: string, data: any | string, id: string | undefined): Promise<IRestResponseDto<T>> {
        if (id && id !== Guid.empty) {
            return NaWiekiService.request<T>('PUT', url, data);
        }

        return NaWiekiService.request<T>('POST', url, data);
    }
}
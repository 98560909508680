import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import AddressHelper from '@/shared/helpers/address-helper';
import AuthStore from '@/stores/auth-store';
import { Component } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex'

@Component({
    components: {
        TemplateChooser: require('@/main/components/home/template-chooser.vue').default,
        BasicInfoPanel: require('@/main/components/home/panels/basic-info-panel.vue').default,
        ContactInfoPanel: require('@/main/components/home/panels/contact-info-panel.vue').default,
        CeremonyPanel: require('@/main/components/home/panels/ceremony-panel.vue').default,
        WebsiteDetailsPanel: require('@/main/components/home/panels/website-details-panel.vue').default,
        ImageSelector: require('@/main/components/home/image-selector.vue').default
    },
    computed: {
        ...mapState({
            website: 'website',
            newWebsite: 'newWebsite',
            customValidationMessage: 'customValidationMessage'
        }),
        ...mapGetters([
            'fullWebsiteAddress'
        ])
        // other stuff
    },

})
export default class HomeComponent extends NaWiekiComponentBase {
    loading = true;
    contactEnabled = true;
    valid = true;
    website!: IWebsiteModel;
    newWebsite!: boolean;
    suspendedDialog = false;

    async mounted() {
        this.getWebsite();
        await this.getMembershipStatus();
    }

    getWebsite() {
        this.loading = true;

        this.NaWiekiService.get<any>('/api/website', false).then((response) => {
            this.loading = false;
            this.$store.commit('setWebsite', response.content);
            var user = AuthStore.getTokenData().sub;
            if(!this.$store.state.website.websiteAddress && user != "admin@nawieki.pl") {
                this.$router.push({ path: '/onboarding/start' });
                return;
            }
        });
    }

    async getMembershipStatus() {
        return await this.$store.dispatch("getMembershipStatus");
    }


    async saveWebsite() {
        var result = (this.$refs.form as any).validate();
        if (!result) {
            this.valid = result;
            return;
        }
        if (!(await this.customValidate())) {
            return;
        }
        this.loading = true;
        this.$store.dispatch('saveWebsite').then((response) => {
            this.loading = false;
            var win = window.open("https://" + (this as any).fullWebsiteAddress(), '_blank');
            win!.focus();
        }).catch((reason: any) => {
            if (reason == "Unauthrorized") {
                this.suspendedDialog = true;
            }
        });
    }

    templateSelected(id: number) {
        this.$store.commit('setTemplate', id);
    }

    async customValidate() {
        return AddressHelper.checkAddressReserved(this.$store.state.website.websiteAddress, this.$store.state.website.domain, this.NaWiekiService).then((response) => {
            if (!response.content) {
                this.$store.commit('setCustomValidationMessage', "Wybrany przez Ciebie adres jest już zajęty! Wybierz inny.");
                return false;
            }

            if (!this.$store.state.website.weddingDate) {
                this.$store.commit('setCustomValidationMessage', "Uzupełnij datę ślubu!");
                return false;
            }
            if (this.$store.state.website.preWeddingEventName && (!this.$store.state.website.preWeddingEventDate || !this.$store.state.website.preWeddingEventTime || !this.$store.state.website.preWeddingEventAddress || !this.$store.state.website.preWeddingEventLatLon)) {
                this.$store.commit('setCustomValidationMessage', "Uzupełnij dane odnośnie wydarzenia przed ślubem lub usuń to wydarzenie!");
                return false;
            }
            if (this.$store.state.website.postWeddingEventName && (!this.$store.state.website.postWeddingEventDate || !this.$store.state.website.postWeddingEventTime || !this.$store.state.website.postWeddingEventAddress || !this.$store.state.website.postWeddingEventLatLon)) {
                this.$store.commit('setCustomValidationMessage', "Uzupełnij dane odnośnie wydarzenia po weselu lub usuń to wydarzenie!");
                return false;
            }
            this.$store.commit('setCustomValidationMessage', "");
            return true;
        });


    }
}
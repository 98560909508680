























































import { Component, Watch } from "vue-property-decorator";
import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { availableDomains } from "@/stores/app-store";
import AddressHelper from "@/shared/helpers/address-helper";
import TemplateChooser from "@/main/components/home/template-chooser.vue";

@Component({
  components: {
    TemplateChooser: require("@/main/components/home/template-chooser.vue")
      .default,
  },
  computed: {
    canGenerate: {
      get() {
        return (
          !(this as any).showDomainReserved &&
          (this as any).websiteAddress != "" &&
          (this as any).selectedTemplateId != 0
        );
      },
    },
  },
})
export default class OnboardFinalComponent extends NaWiekiComponentBase {
  private websiteAddress: string = "";
  private domains: string[] = availableDomains;
  private selectedDomain = availableDomains[0];
  private showDomainReserved = false;
  private selectedTemplateId = 0;
  public refs = this.$refs as any;

  public rules: any = {
    required: (value: string) => !!value || "Pole wymagane.",
    noWhitespaces: (value: string) => {
      return !/\s/.test(value) || "Nie używaj spacji w adresie.";
    },
    noSpecialChars: (value: string) => {
      return !/[^a-zA-Z0-9 ]/g.test(value) || "Nie używaj znaków specjalnych w adresie.";
    }
  };

  @Watch("websiteAddress")
  @Watch("selectedDomain")
  async onAddressChanged(val: string, oldVal: string) {
    if (this.websiteAddress == "") {
      return;
    }
    let result = await AddressHelper.checkAddressReserved(
      this.websiteAddress,
      this.selectedDomain,
      this.NaWiekiService
    );
    this.showDomainReserved = !result.content;
  }

  mounted() {
    if (!this.$store.state.website.weddingDate) {
      this.$router.push({ path: "/onboarding/start" });
      return;
    }
    this.websiteAddress = AddressHelper.buildWebsiteAddress(this.$store);
  }

  templateSelected(id: number) {
    this.$store.commit("setTemplate", id);
    this.selectedTemplateId = id;
  }

  generate() {
    if (!this.refs.form.validate()) {
      return;
    }
    this.$store.commit("setWebsiteAddress", this.websiteAddress);
    this.$store.commit("setDomain", this.selectedDomain);
    this.$router.push({ path: "/onboarding/creating" });
  }
}

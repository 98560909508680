import NaWieki from '@/shared/application/nawieki';
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { IMembershipStatusModel } from '@/shared/models/membership-status-model';
import { MembershipType } from '@/shared/models/membership-type';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';


@Component({
    components: {
        MembershipInfo: require('@/main/components/menu/membership-info.vue').default
    },
    computed: {
        ...mapState({
            membership: "membership"
        }),
        showPayments: {
            get() {
                var membership = (this as any).membership as IMembershipStatusModel;
                return membership.membership == MembershipType.Basic;
            }
        }
    }
})
export default class AsideMenuComponent extends NaWiekiComponentBase {
    drawer = true;
    isAdmin = false;

    get mainMenuItems() {
        return [
            { icon: 'mdi-tune', text: "Ustawienia strony", link: '/main/home', isGranted: true },
            { icon: 'mdi-account-details', text: this.$t('Guests'), link: '/main/guests-list', isGranted: true },
            { icon: 'mdi-comment-question', text: this.$t('GuestsForm'), link: '/main/guests-form', isGranted: NaWieki.auth.isGranted('Permissions_GuestsQuestions') },
            { icon: 'mdi-message-text-clock', text: this.$t('Communications'), link: '/main/communications', isGranted: NaWieki.auth.isGranted('Permissions_GuestsCommunication') },
            { icon: 'mdi-gift', text: "Lista prezentów", link: '/main/gifts', isGranted: NaWieki.auth.isGranted('Permissions_Gifts') },
            { icon: 'mdi-earth-plus', text: "Wersje językowe", link: '/main/translations', isGranted: NaWieki.auth.isGranted('Permissions_Translations') },
        ];
    }

    get grantedMainMenuItems() {
        return this.mainMenuItems.filter(x => x.isGranted == undefined || x.isGranted);
    }

    get adminMenuItems() {
        return [
            { icon: 'mdi-account-multiple', text: this.$t('Users'), link: '/admin/user-list' },
            { icon: 'mdi-briefcase-account', text: this.$t('Roles'), link: '/admin/role-list' }
        ];
    }

    mounted() {
        this.$root.$on('drawerChanged',
            () => {
                this.drawer = !this.drawer;
            });
    }
}
import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import AppConsts from '@/shared/application/nawieki';
import { mapState } from 'vuex'

@Component({
    components: {
        Panel: require('@/main/components/home/panels/panel.vue').default
    },
    computed: {
        ...mapState({
            website: 'website',
            newWebsite: 'newWebsite'
        }),
        bridePhone: {
            get() {
                return this.$store.state.website.bridePhone
            },
            set(value) {
                this.$store.commit('setBridePhone', value)
            }
        },
        groomPhone: {
            get() {
                return this.$store.state.website.groomPhone
            },
            set(value) {
                this.$store.commit('setGroomPhone', value)
            }
        },
        contactPersonName: {
            get() {
                return this.$store.state.website.contactPersonName
            },
            set(value) {
                this.$store.commit('setContactPersonName', value)
            }
        },
        contactPersonPhone: {
            get() {
                return this.$store.state.website.contactPersonPhone
            },
            set(value) {
                this.$store.commit('setContactPersonPhone', value)
            }
        },
    },
})
export default class ContactInfoPanelComponent extends NaWiekiComponentBase {


    nameRules: Array<(arg: string) => string | boolean> = [
        v => !!v || 'Pole jest wymagane',
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ];

    rules = {
        required: (value: string) => !!value || 'Pole jest wymagane.',
        counter: (value: string) => value.length <= 20 || 'Max 20 characters',
        email: (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Niepoprawny e-mail.'
        },
    };
}
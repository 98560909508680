<template>
  <v-container>
    <v-row>
      <div class="centerize">
        <h1 class="text-center">Wystąpił błąd podczas przetwarzania płatności</h1>
        <br /><br />
        <div class="d-flex">
          <v-icon class="error-sign">mdi-alert-circle-outline</v-icon>
        </div>
        <br /><br />
        <h3 class="text-justify">
          Prosimy o kontakt e-mailowy na adres
          <a href="mailto:kontakt@nawieki.pl">kontakt@nawieki.pl</a> w celu
          rozwiązania sytuacji
        </h3>
        <v-btn class="centerize" color="primary" x-large @click="$router.push({ path: '/' })">Powrót</v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {

};
</script>

<style>
.centerize {
  width: 550px;
  margin: auto;
  margin-top: 150px;
}

.error-sign {
  margin: auto;
  font-size: 100px !important;
  color: #f44336 !important;
}
</style>
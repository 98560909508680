import NaWiekiComponentBase from "@/shared/application/nawieki-component-base";
import { IMembershipStatusModel } from "@/shared/models/membership-status-model";
import { MembershipType } from "@/shared/models/membership-type";
import { Component } from "vue-property-decorator";
import { mapState } from "vuex";

@Component({
    computed: {
        ...mapState({
            membership: "membership"
        }),
        membershipInfo: {
            get() {
                if (Object.keys((this as any).membership).length === 0) {
                    return "";
                }
                (this as any).membershipAlertShown = true;

                var membership = (this as any).membership as IMembershipStatusModel;
                if (membership.isActive && membership.membership == MembershipType.Basic) {
                    return `Pakiet podstawowy kończy się za ${membership.daysLeft} dni.`

                } 
                 else if (!membership.isActive) {
                    return `Twój pakiet wygasł. Przejdź do zakładki Płatności by odnowić pakiet.`;
                }
                (this as any).membershipAlertShown = false;

            }
        },
        infoColor: {
            get() {
                if (Object.keys((this as any).membership).length === 0) {
                    return "";
                }
                var membership = (this as any).membership as IMembershipStatusModel;
                if (membership.isActive && membership.membership == MembershipType.Basic && membership.daysLeft! > 5) {
                    return "transparent";
                }
                else if (membership.isActive && membership.membership == MembershipType.Basic && membership.daysLeft! <= 5) {
                    (this as any).isLight = true;
                    return "rgb(244, 219, 142)";
                }
                else if (!membership.isActive) {
                    return "error";
                }
            }
        },
        mobileIcon: {
            get() {
                if (Object.keys((this as any).membership).length === 0) {
                    return "mdi-help-circle";
                }
                var membership = (this as any).membership as IMembershipStatusModel;
                if (membership.isActive && membership.membership == MembershipType.Basic && membership.daysLeft! > 5) {
                    return "mdi-help-circle";
                }
                else if (membership.isActive && membership.membership == MembershipType.Basic && membership.daysLeft! <= 5) {
                    (this as any).isLight = true;
                    return "mdi-alert";
                }
                else if (!membership.isActive) {
                    return "mdi-alert-circle";
                }
            }
        }
    },
})
export default class MembershipAlertComponent extends NaWiekiComponentBase {
    public membershipAlertShown = false;
    public membership!: IMembershipStatusModel;
    public isLight = false;
    public modalInfo = false;

    public membershipsLabels = [
        { type: MembershipType.Silver, value: "🤍 Srebrny" },
        { type: MembershipType.Gold, value: "👑 Złoty" },
        { type: MembershipType.Diamond, value: "💎 Diamentowy" },
    ];
}


import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import { ICommunicationModel } from '@/shared/models/communication-model';
import { Component } from 'vue-property-decorator';

@Component
export default class CommunicationsComponent extends NaWiekiComponentBase {

    communications: Array<ICommunicationModel> = [];
    dialogDelete: boolean = false;
    dialogSendTestSms: boolean = false;
    dialogSendTestEmail: boolean = false;
    dialogTestCommunicationSent: boolean = false;
    plannedSmsUsage: number = 0;
    smsRemaining: number = -1;
    testSmses: string[] = [];
    testEmails: string[] = [];

    selectedSmsNumber: string = "";
    selectedEmailAddress: string = "";

    itemToDelete = {} as ICommunicationModel;

    headers = [
        {
            text: 'Nazwa',
            align: 'start',
            value: 'name',
        },
        { text: 'Treść', value: 'content', width: "40%" },
        { text: 'Data wysyłki', value: 'plannedDate' },
        { text: 'Godzina wysyłki', value: 'plannedTime' },
        { text: 'Typ', value: 'type' },
        { text: 'Akcje', value: 'actions' }
    ];

    getCommunications() {
        this.NaWiekiService.get<any>('/api/communication', false).then((response) => {
            this.communications = response.content as Array<ICommunicationModel>;
        });
    }

    getPlannedSmsUsage() {
        this.NaWiekiService.get<any>('/api/communication/smsUsage', false).then((response) => {
            this.plannedSmsUsage = response.content;
        });
    }

    getWebsite() {
        this.NaWiekiService.get<any>('/api/website', false).then((response) => {
            let website = response.content as IWebsiteModel;
            this.smsRemaining = website.smsRemaining;
            if (website.groomPhone) {
                this.testSmses.push(website.groomPhone);
            }
            if (website.bridePhone) {
                this.testSmses.push(website.bridePhone);
            }
            if (website.groomEmail) {
                this.testEmails.push(website.groomEmail);
            }
            if (website.brideEmail) {
                this.testEmails.push(website.brideEmail);
            }
        });
    }

    async mounted() {
        await this.$store.dispatch('loadWebsiteIfNeeded');
        this.getCommunications();
        this.getPlannedSmsUsage();
        this.getWebsite();
    }

    getHumanFriendlyDate(date: string) {
        return new Date(date).toLocaleString();
    }

    editItem(item: ICommunicationModel) {
        this.$router.push("/main/communication/" + item.id);
    }

    deleteItemConfirm() {
        this.NaWiekiService.delete('/api/communication/' + this.itemToDelete.id, false).then((response) => {
            this.dialogDelete = false;
            this.communications = this.communications.filter(x => x.id != this.itemToDelete.id);
        });
    }

    showDeleteItemConfirmation(item: ICommunicationModel) {
        this.itemToDelete = item;
        this.dialogDelete = true;
    }

    closeDelete() {
        this.dialogDelete = false
    }

    getStatusColor(type: number) {
        switch (type) {
            case 0: return "green";
            case 1: return "light-blue";
            case 2: return "teal";
        }
    }

    getStatusLabel(type: number) {
        switch (type) {
            case 0: return "Wysyłka do wszystkich potwierdzonych oraz zaproszonych gości";
            case 1: return "Wysyłka do wybranych gości";
            case 2: return "Wysyłka do zaproszonych gości, którzy jeszcze nie potwierdzili obecności";
        }
    }

    showSendTestSms() {
        this.dialogSendTestSms = true;
    }

    showSendTestEmail() {
        this.dialogSendTestEmail = true;
    }

    sendTestSms() {
        this.dialogSendTestSms = false;
        this.NaWiekiService.post<any>('/api/communication/sendtestsms/' + this.selectedSmsNumber, false).then((response) => {
            this.dialogTestCommunicationSent = true;
            this.getWebsite();
        });
    }

    sendTestEmail() {
        this.dialogSendTestEmail = false;
        this.NaWiekiService.post<any>('/api/communication/sendtestemail/' + this.selectedEmailAddress, false).then((response) => {
            this.dialogTestCommunicationSent = true;
        });
    }
}
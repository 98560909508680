import NaWiekiComponentBase from '@/shared/application/nawieki-component-base';
import DietHelper from '@/shared/helpers/diet-helper';
import { Component } from 'vue-property-decorator';

@Component({
    computed: {
        selectedAftermatch: {
            get() {
                return this.$store.state.website.aftermathPresenceQuestion ? 1: 0;
            },
            set(value) {
                this.$store.commit('setAftermathPresenceQuestion', value == 1 ? true : false);
            }
        },
        selectedVaccination: {
            get() {
                if(this.$store.state.website.isVaccinatedQuestion) {
                    return 1;
                } 
                if(this.$store.state.website.vaccinationDetailsQuestion) {
                    return 2;
                }
                return 0;
            },
            set(value) {
                switch(value) {
                    case 0:
                        this.$store.commit('setIsVaccinatedQuestion', false);
                        this.$store.commit('setVaccinationDetailsQuestion', false);
                        break;
                    case 1:
                        this.$store.commit('setIsVaccinatedQuestion', true);
                        this.$store.commit('setVaccinationDetailsQuestion', false);
                        break;
                    case 2:
                        this.$store.commit('setIsVaccinatedQuestion', false);
                        this.$store.commit('setVaccinationDetailsQuestion', true);
                        break;
                }
            }
        },
        selectedAccommodation: {
            get() {
                return this.$store.state.website.accommodationNeededQuestion ? 1: 0;
            },
            set(value) {
                this.$store.commit('setAccommodationNeededQuestion', value == 1 ? true : false);
            }
        },
    }
})
export default class GuestsFormComponent extends NaWiekiComponentBase {
    private yesNo = [{ text: "Tak", value: 1 }, { text: "Nie", value: 0 }];
    private vaccinationOptions = [{ text: "Nie", value: 0 }, { text: "Pytaj, czy gość jest zaszczepiony (tak/nie)", value: 1 }, { text: "Poproś gościa o wpisanie numeru szczepionki", value: 2 }];

    private selectedChild = 0;
    private selectedDiet = 0;

    private availableDiets = DietHelper.getDietsDict();
    private selectedDiets = [];

    private childEnabled = false;
    private teenEnabled = false;

    private selectedChildAge = 3;
    private selectedTeenAge = 18;

    async mounted() {
        await this.$store.dispatch('loadWebsiteIfNeeded');
        if (Object.keys(this.$store.state.website).length === 0) {
            this.$store.dispatch('loadWebsite').then((res) => {
                this.loadData();
            });
        } else {
            this.loadData();
        }

    }

    loadData() {
        if (this.$store.state.website.selectedDiets.length > 0) {
            this.selectedDiet = 1;
            this.selectedDiets = this.$store.state.website.selectedDiets;
        }
        if (this.$store.state.website.teenAge !== null || this.$store.state.website.childAge !== null) {
            this.selectedChild = 1;
            if (this.$store.state.website.teenAge !== null) {
                this.teenEnabled = true;
                this.selectedTeenAge = this.$store.state.website.teenAge;
            }

            if (this.$store.state.website.childAge !== null) {
                this.childEnabled = true;
                this.selectedChildAge = this.$store.state.website.childAge;
            }
        }
    }

    async saveQuestions() {
        this.$store.dispatch('saveGuestQuestions',
            {
                selectedDiets: this.selectedDiet === 1 ? this.selectedDiets : [],
                childAge: this.selectedChild === 1 && this.childEnabled ? this.selectedChildAge : null,
                teenAge: this.selectedChild === 1 && this.teenEnabled ? this.selectedTeenAge : null
            }).then((response) => {
                this.swalToast(3000, 'success', "Zapisano");
            });
    }
}
import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthStore from '@/stores/auth-store';
import accountLayout from '@/account/account-layout.vue';
import mainLayout from '@/main/main-layout.vue';
import onboardingLayout from './onboarding/onboarding-layout.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/main/home' },
        {
            path: '/account',
            component: accountLayout,
            children: [
                { path: 'login', component: require('@/account/views/login/login.vue').default },
                { path: 'register', component: require('@/account/views/register/register.vue').default },
                { path: 'forgot-password', component: require('@/account/views/manage/forgot-password.vue').default },
                { path: 'reset-password', component: require('@/account/views/manage/reset-password.vue').default },
                { path: 'confirmation-error', component: require('@/account/views/manage/confirmation-error.vue').default },
                { path: 'email-confirmed', component: require('@/account/views/manage/email-confirmed.vue').default }
            ]
        },
        {
            path: '/main',
            component: mainLayout,
            meta: { requiresAuth: true },
            children: [
                { path: 'home', component: require('@/main/views/home/home.vue').default },
                { path: 'guests-list', component: require('@/main/views/guests/guests-list.vue').default },
                { path: 'guests-form', component: require('@/main/views/guests/guests-form.vue').default },
                { path: 'communication/:id?', component: require('@/main/views/communications/communication.vue').default },
                { path: 'communications', component: require('@/main/views/communications/communications.vue').default },
                { path: 'gifts', component: require('@/main/views/gifts/gifts.vue').default },
                { path: 'user-list', component: require('@/main/views/users/user-list.vue').default },
                { path: 'role-list', component: require('@/main/views/roles/role-list.vue').default },
                { path: 'translations', component: require('@/main/views/translations/translations.vue').default },
                { path: 'translation/:code/:id?', component: require('@/main/views/translations/translation.vue').default },
                { path: 'payment', component: require('@/main/views/payment/payment.vue').default },
                { path: 'payment/success', component: require('@/main/views/payment/payment_success.vue').default },
                { path: 'payment/error', component: require('@/main/views/payment/payment_error.vue').default }
            ]
        },
        {
            path: '/onboarding',
            component: onboardingLayout,
            meta: { requiresAuth: true },
            children: [
                { path: 'start', component: require('@/onboarding/views/onboard-start.vue').default },
                { path: 'details', component: require('@/onboarding/views/onboard-details.vue').default },
                { path: 'event', component: require('@/onboarding/views/onboard-event.vue').default },
                { path: 'final', component: require('@/onboarding/views/onboard-final.vue').default },
                { path: 'creating', component: require('@/onboarding/views/onboard-creating.vue').default },
            ]
        },
        {
            path: '/admin',
            component: mainLayout,
            meta: { requiresAuth: true },
            children: [
                { path: 'user-list', component: require('@/main/views/users/user-list.vue').default },
                { path: 'role-list', component: require('@/main/views/roles/role-list.vue').default }
            ]
        }
    ]
});

router.beforeEach((to: any, from: any, next: any) => {
    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!AuthStore.isSignedIn()) {
            next({
                path: '/account/login',
                query: { redirect: to.fullPath }
            });
        }
    }
    next(); // make sure to always call next()!
});

export default router;